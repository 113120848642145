import { DateTime } from "luxon";

const dateTimeRegExp = /20\d{2}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:00/;
const customPeriodRegExp = new RegExp("^(" + dateTimeRegExp.source + ")\\|(" + dateTimeRegExp.source + ")$");
const periods = ["1h", "3h", "6h", "12h", "24h", "2d", "7d", "30d", "12m"];

export const periodLabels = new Map<string, string>([
  ["1h", "1 hour"],
  ["3h", "3 hours"],
  ["6h", "6 hours"],
  ["12h", "12 hours"],
  ["24h", "24 hours"],
  ["2d", "2 days"],
  ["7d", "7 days"],
  ["30d", "30 days"],
  ["12m", "12 months"],
]);

export const getInitialPeriod = ({
  key = "period",
  customKey = "customPeriod",
  defaultPeriod = "24h",
  allowCustom = true,
}: {
  key?: string;
  customKey?: string;
  defaultPeriod?: string;
  allowCustom?: boolean;
} = {}): string => {
  const period = localStorage.getItem(key);
  const customPeriod = allowCustom ? sessionStorage.getItem(customKey) : undefined;
  if (customPeriod && isCustomPeriod(customPeriod)) {
    return customPeriod;
  } else if (period && periods.includes(period)) {
    return period;
  } else {
    return defaultPeriod;
  }
};

export const validatePeriod = (period: string) => {
  return periods.includes(period) || isCustomPeriod(period);
};

export const isCustomPeriod = (period: string) => {
  return period.length === 39 && customPeriodRegExp.test(period);
};

export const parseCustomPeriod = (period: string) => {
  const match = period.match(customPeriodRegExp);
  if (match) {
    const periodStartEnd = {
      start: DateTime.fromISO(match[1]),
      end: DateTime.fromISO(match[2]),
    };
    if (periodStartEnd.start.isValid && periodStartEnd.end.isValid) {
      return periodStartEnd;
    }
  }
};

export const getCustomPeriodString = (start: DateTime, end: DateTime) => {
  const format = "yyyy-MM-dd'T'HH:mm:ss";
  return `${start.toFormat(format)}|${end.toFormat(format)}`;
};

type PeriodFormatOptions = {
  expandCustom?: boolean;
};

export const formatPeriod = (period: string, { expandCustom = true }: PeriodFormatOptions = {}) => {
  if (periodLabels.has(period)) {
    return periodLabels.get(period);
  }
  const customPeriod = parseCustomPeriod(period);
  if (customPeriod) {
    if (!expandCustom) {
      return "Custom";
    }
    const useAmericanDateFormat = navigator.language === "en-US";
    const dayMonthYearFormat = useAmericanDateFormat ? "MMM d, yyyy" : "d MMM yyyy";
    const { start, end } = customPeriod;
    const hyphen = " – "; // punctuation space and en dash
    if (start.hasSame(end, "day")) {
      return start.toFormat(dayMonthYearFormat) + ", " + start.toFormat("HH:mm") + hyphen + end.toFormat("HH:mm");
    } else if (
      start.hour === 0 &&
      start.minute === 0 &&
      start.second === 0 &&
      end.hour === 0 &&
      end.minute === 0 &&
      end.second === 0
    ) {
      const endPrevDay = end.minus({ days: 1 });
      if (end.diff(start, "days").days === 1) {
        return start.toFormat(dayMonthYearFormat);
      } else if (start.hasSame(endPrevDay, "month")) {
        if (useAmericanDateFormat) {
          return start.toFormat("MMM d") + hyphen + endPrevDay.toFormat("d, yyyy");
        } else {
          return start.toFormat("d") + hyphen + endPrevDay.toFormat("d MMM yyyy");
        }
      } else if (start.hasSame(endPrevDay, "year")) {
        if (useAmericanDateFormat) {
          return start.toFormat("MMM d") + hyphen + endPrevDay.toFormat("MMM d, yyyy");
        } else {
          return start.toFormat("d MMM") + hyphen + endPrevDay.toFormat("d MMM yyyy");
        }
      } else {
        return start.toFormat(dayMonthYearFormat) + hyphen + endPrevDay.toFormat(dayMonthYearFormat);
      }
    } else {
      const format = `${dayMonthYearFormat}, HH:mm`;
      return start.toFormat(format) + hyphen + end.toFormat(format);
    }
  }
};

export const formatPeriodStep = (start: DateTime, end: DateTime) => {
  const diff = end.diff(start);
  const useAmericanDateFormat = navigator.language === "en-US";
  const dayMonthFormat = useAmericanDateFormat ? "MMM d" : "d MMM";
  const hyphen = " – "; // punctuation space and en dash

  if (diff.as("days") >= 28) {
    return start.toFormat("MMMM yyyy");
  } else if (diff.as("days") === 1) {
    return start.toFormat(useAmericanDateFormat ? "EEEE, MMMM d" : "EEEE, d MMMM");
  } else if (diff.as("hours") > 24) {
    const sameMonth = start.hasSame(end, "month");
    const sameYear = start.hasSame(end, "year");
    const endPrevDay = end.minus({ days: 1 });
    if (sameMonth && sameYear) {
      return useAmericanDateFormat
        ? start.toFormat("MMMM d") + hyphen + endPrevDay.toFormat("d, yyyy")
        : start.toFormat("d") + hyphen + endPrevDay.toFormat("d MMMM yyyy");
    } else if (sameYear) {
      return useAmericanDateFormat
        ? start.toFormat("MMMM d") + hyphen + endPrevDay.toFormat("MMMM d, yyyy")
        : start.toFormat("d MMMM") + hyphen + endPrevDay.toFormat("d MMMM yyyy");
    } else {
      return useAmericanDateFormat
        ? start.toFormat("MMMM d, yyyy") + hyphen + endPrevDay.toFormat("MMMM d, yyyy")
        : start.toFormat("d MMMM yyyy") + hyphen + endPrevDay.toFormat("d MMMM yyyy");
    }
  } else {
    const startFormatted = formatDate(start, dayMonthFormat) + ", " + start.toFormat("HH:mm");
    if (diff.as("minutes") <= 1) {
      return startFormatted;
    } else {
      const sameDay = start.hasSame(end, "day");
      const endFormatted = sameDay
        ? end.toFormat("HH:mm")
        : formatDate(end, dayMonthFormat) + ", " + end.toFormat("HH:mm");
      return `${startFormatted}${hyphen}${endFormatted}`;
    }
  }
};

export const formatDate = (dt: DateTime, dayMonthFormat: string) => {
  const isToday = dt.hasSame(DateTime.local(), "day");
  const isYesterday = dt.hasSame(DateTime.local().minus({ days: 1 }), "day");
  return isToday ? "Today" : isYesterday ? "Yesterday" : dt.toFormat("EEE " + dayMonthFormat);
};
