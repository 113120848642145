import React, { createContext, useContext, useEffect, useState } from "react";

import ConfirmationModal from "../modals/ConfirmationModal";

type Confirmation = {
  title: React.ReactNode;
  body: React.ReactNode | React.ReactNode[];
  onConfirm: () => void;
  confirmButtonVariant?: string;
  confirmButtonText?: string;
  dontAskAgainKey?: string;
};

type ConfirmationContextType = {
  maybeConfirm: (confirmation: Confirmation) => void;
};

const ConfirmationContext = createContext<ConfirmationContextType | undefined>(undefined);

export function useConfirmation() {
  const context = useContext(ConfirmationContext);
  if (context === undefined) {
    throw new Error("useConfirmation must be used within a ConfirmationProvider");
  }
  return context;
}

export const ConfirmationContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const [confirmation, setConfirmation] = useState<Confirmation>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) {
      setConfirmation(undefined);
    }
  }, [showModal]);

  const maybeConfirm = ({
    onConfirm,
    confirmButtonVariant = "primary",
    confirmButtonText = "Confirm",
    dontAskAgainKey,
    title,
    body,
  }: Confirmation) => {
    if (dontAskAgainKey && localStorage.getItem("dontAskAgain:" + dontAskAgainKey) === "true") {
      onConfirm();
    } else {
      setConfirmation({ onConfirm, confirmButtonVariant, confirmButtonText, dontAskAgainKey, title, body });
      setShowModal(true);
    }
  };

  return (
    <ConfirmationContext.Provider value={{ maybeConfirm }}>
      {children}
      {confirmation && (
        <ConfirmationModal
          show={showModal}
          setShow={setShowModal}
          onConfirm={confirmation.onConfirm}
          confirmButtonVariant={confirmation.confirmButtonVariant}
          confirmButtonText={confirmation.confirmButtonText}
          dontAskAgainKey={confirmation.dontAskAgainKey}
          title={confirmation.title}
        >
          {confirmation.body}
        </ConfirmationModal>
      )}
    </ConfirmationContext.Provider>
  );
};
