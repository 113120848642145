import classNames from "classnames";
import { DateTime } from "luxon";
import { useState } from "react";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import Markdown from "react-markdown";

import changelog from "../changelog/changelog";
import Pagination from "../components/Pagination";
import "./ChangelogModal.css";

type ChangelogModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

function ChangelogModal({ show, setShow }: ChangelogModalProps) {
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;
  const numberOfPages = Math.ceil(changelog.length / itemsPerPage);
  const lastSeen = localStorage.getItem("changelogLastSeen");

  const onHide = () => {
    if (changelog.length > 0) {
      localStorage.setItem("changelogLastSeen", changelog[0].index);
    }
    setShow(false);
  };

  return (
    <Modal className="ChangelogModal" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>What's new in Apitally ✨</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {changelog.slice(itemsPerPage * (page - 1), itemsPerPage * page).map((item, index) => {
          const isNew = lastSeen && item.index > lastSeen;
          return (
            <div key={item.index} className={classNames({ "mt-6": index > 0 })}>
              <div className="small text-muted mb-1">
                {item.date.toLocaleString(DateTime.DATE_SHORT)}
                {isNew && (
                  <Badge pill bg="primary" className="outline-primary ms-2" style={{ position: "relative", top: -2 }}>
                    new
                  </Badge>
                )}
              </div>
              <h2 className="fw-normal">{item.title}</h2>
              <div className="description">
                <Markdown>{item.description}</Markdown>
              </div>
              {item.image && <img src={item.image} alt="" className="img-fluid mt-2 rounded border border-1 p-n1" />}
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center bg-light">
        <div>
          <Pagination page={page} numberOfPages={numberOfPages} setPage={setPage} />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangelogModal;
