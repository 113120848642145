import { useAuth0 } from "@auth0/auth0-react";
import {
  faBell,
  faBoltLightning,
  faBug,
  faBullseye,
  faChartSimple,
  faFingerprint,
  faHeartPulse,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";

import "./MobileNavbar.css";
import UserDropdownMenu from "./UserDropdownMenu";
import UserImage from "./UserImage";

type MobileNavbarProps = {
  setShowChangelogModal: (show: boolean) => void;
  setShowFeedbackModal: (show: boolean) => void;
};

function MobileNavbar({ setShowChangelogModal, setShowFeedbackModal }: MobileNavbarProps) {
  const { user } = useAuth0();

  return (
    <Navbar sticky="top" className="MobileNavbar d-md-none">
      <Container fluid>
        <Nav className="py-2 gap-1 overflow-auto">
          <Nav.Link as={NavLink} to="/apps">
            <FontAwesomeIcon icon={faHome} />
          </Nav.Link>
          <Nav.Link as={NavLink} to="/traffic">
            <FontAwesomeIcon icon={faChartSimple} fixedWidth />
          </Nav.Link>
          <Nav.Link as={NavLink} to="/errors">
            <FontAwesomeIcon icon={faBug} fixedWidth />
          </Nav.Link>
          <Nav.Link as={NavLink} to="/performance">
            <FontAwesomeIcon icon={faBoltLightning} fixedWidth />
          </Nav.Link>
          <Nav.Link as={NavLink} to="/consumers">
            <FontAwesomeIcon icon={faFingerprint} fixedWidth />
          </Nav.Link>
          <Nav.Link as={NavLink} to="/endpoint-groups">
            <FontAwesomeIcon icon={faBullseye} fixedWidth />
          </Nav.Link>
          <Nav.Link as={NavLink} to="/uptime">
            <FontAwesomeIcon icon={faHeartPulse} fixedWidth />
          </Nav.Link>
          <Nav.Link as={NavLink} to="/alerts">
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </Nav.Link>
        </Nav>
        <Dropdown className="no-caret ms-auto">
          <Dropdown.Toggle className="p-2">
            <UserImage user={user} variant="light" className="user-image" />
          </Dropdown.Toggle>
          <UserDropdownMenu
            includeSupportAndFeedback
            setShowChangelogModal={setShowChangelogModal}
            setShowFeedbackModal={setShowFeedbackModal}
            align="end"
            renderOnMount
          />
        </Dropdown>
      </Container>
    </Navbar>
  );
}

export default MobileNavbar;
