import { Field, FieldProps, Form, Formik } from "formik";
import Button from "react-bootstrap/Button";
import { default as BootstrapForm } from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useGlobal } from "../contexts/GlobalContext";

type FeedbackModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

function FeedbackModal({ show, setShow }: FeedbackModalProps) {
  const { backendClient } = useGlobal();

  const submitFeedback = async (feedback: string) => {
    if (backendClient) {
      const promise = backendClient.users.submitFeedback({ requestBody: { feedback } });
      toast.promise(promise, {
        pending: "Submitting feedback...",
        success: "Thank you for your feedback!",
        error: "Failed to submit feedback.",
      });
      await promise;
    }
  };

  return (
    <Formik
      initialValues={{ feedback: "" }}
      onSubmit={async (values, { resetForm }) => {
        await submitFeedback(values.feedback);
        resetForm();
        setShow(false);
      }}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <BootstrapForm.Group controlId="formResponse">
                <BootstrapForm.Label>What can we do to improve Apitally?</BootstrapForm.Label>
                <Field name="feedback">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      as="textarea"
                      placeholder="Start typing..."
                      rows={5}
                      maxLength={4096}
                      autoFocus
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.feedback}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Discard
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default FeedbackModal;
