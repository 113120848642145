import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";

import classNames from "classnames";
import "./TableCellDropdown.css";

type TableCellDropdownProps = {
  children: React.ReactNode;
  className?: string;
} & Omit<React.HTMLProps<HTMLTableCellElement>, "className">;

function TableCellDropdown({ children, className, ...tdProps }: TableCellDropdownProps) {
  const icon = <FontAwesomeIcon icon={faEllipsisVertical} />;
  return (
    <td style={{ width: 40 }} className={classNames("TableCellDropdown", className)} {...tdProps}>
      <DropdownButton title={icon} drop="start" variant="light">
        {children}
      </DropdownButton>
    </td>
  );
}

export default TableCellDropdown;
