import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import capitalize from "lodash/capitalize";

import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { formatPeriod, isCustomPeriod } from "../utils/period";
import FilterBadge from "./FilterBadge";
import "./FilterBadges.css";

type FilterBadgesProps = {
  level?: number;
  clearLevel?: () => void;
  showAll?: boolean;
  className?: string;
};

function FilterBadges({ level, clearLevel, showAll, className }: FilterBadgesProps) {
  const { backendClient } = useGlobal();
  const {
    app,
    period,
    resetPeriod,
    env,
    setEnv,
    consumerId,
    setConsumerId,
    consumerGroupId,
    setConsumerGroupId,
    endpointGroupId,
    setEndpointGroupId,
  } = useFilters();

  const customPeriod = period && isCustomPeriod(period);
  const alwaysShowBadges = showAll || consumerId || consumerGroupId || endpointGroupId || customPeriod;
  const mdShowBadges = alwaysShowBadges || period || env;

  const consumerQueryParams = { appId: app?.id || 0, consumerId: consumerId || 0 };
  const { data: consumer } = useQuery({
    queryKey: ["consumer", consumerQueryParams],
    queryFn: () => backendClient!.consumers.getConsumer(consumerQueryParams),
    enabled: !!backendClient && !!app && !!consumerId,
  });
  const consumerGroupQueryParams = { appId: app?.id || 0, consumerGroupId: consumerGroupId || 0 };
  const { data: consumerGroup } = useQuery({
    queryKey: ["consumerGroup", consumerGroupQueryParams],
    queryFn: () => backendClient!.consumers.getConsumerGroup(consumerGroupQueryParams),
    enabled: !!backendClient && !!app && !!consumerGroupId,
  });
  const endpointGroupQueryParams = { appId: app?.id || 0, endpointGroupId: endpointGroupId || 0 };
  const { data: endpointGroup } = useQuery({
    queryKey: ["endpointGroup", endpointGroupQueryParams],
    queryFn: () => backendClient!.endpoints.getEndpointGroup(endpointGroupQueryParams),
    enabled: !!backendClient && !!app && !!endpointGroupId,
  });

  return (
    <div
      className={classNames(
        "FilterBadges",
        { "mb-4": mdShowBadges },
        "mb-md-0",
        { "d-md-none": !alwaysShowBadges },
        className,
      )}
    >
      {period && (
        <FilterBadge
          icon={faClock}
          label="Period"
          value={formatPeriod(period)}
          className={!customPeriod && !showAll ? "d-md-none" : undefined}
          removeFilter={customPeriod ? resetPeriod : undefined}
        />
      )}
      {env && (
        <FilterBadge
          icon="/icons/layer-group-regular.svg"
          label="Env"
          value={capitalize(env.slug)}
          removeFilter={() => setEnv(undefined)}
          className={!showAll ? "d-md-none" : undefined}
        />
      )}
      {consumerId && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Consumer"
          value={consumer?.name || "..."}
          removeFilter={() => setConsumerId(undefined)}
        />
      )}
      {consumerGroupId && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Consumer group"
          value={consumerGroup?.name || "..."}
          removeFilter={() => setConsumerGroupId(undefined)}
        />
      )}
      {endpointGroupId && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Endpoint group"
          value={endpointGroup?.name || "..."}
          removeFilter={() => setEndpointGroupId(undefined)}
        />
      )}
      {level && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Level"
          value={level.toString()}
          removeFilter={clearLevel}
        />
      )}
    </div>
  );
}

export default FilterBadges;
