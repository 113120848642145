import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { useGlobal } from "../contexts/GlobalContext";
import ChangelogModal from "../modals/ChangelogModal";
import FeedbackModal from "../modals/FeedbackModal";
import Watermark from "../static/logo.svg?react";
import "./MainLayout.scss";
import MobileNavbar from "./MobileNavbar";
import Sidebar from "./Sidebar";

function MainLayout({ children }: { children: React.ReactNode }) {
  const { showFeedbackModal, setShowFeedbackModal } = useGlobal();
  const [showChangelogModal, setShowChangelogModal] = useState(false);

  return (
    <div className="MainLayout d-block d-md-grid h-100">
      <ChangelogModal show={showChangelogModal} setShow={setShowChangelogModal} />
      <FeedbackModal show={showFeedbackModal} setShow={setShowFeedbackModal} />
      <Sidebar setShowChangelogModal={setShowChangelogModal} setShowFeedbackModal={setShowFeedbackModal} />
      <MobileNavbar setShowChangelogModal={setShowChangelogModal} setShowFeedbackModal={setShowFeedbackModal} />
      <Container fluid as="main" className="overflow-auto" style={{ minHeight: "100%" }}>
        <Row className="justify-content-center">
          <Col className="px-3 px-md-5 px-xl-7">
            {children}
            <div className="text-center">
              <Watermark className="watermark" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MainLayout;
