import { useQuery } from "@tanstack/react-query";
import {
  ChartData,
  Chart as ChartJS,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import { rgba } from "polished";
import { memo } from "react";
import { Line } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";

ChartJS.register(Filler, LinearScale, TimeSeriesScale, LineController, LineElement, PointElement, Title, Tooltip);

type RequestsPerMinuteLineChartProps = {
  enabled?: boolean;
  displayTitle?: boolean;
};

function RequestsPerMinuteLineChart({ enabled = true, displayTitle = true }: RequestsPerMinuteLineChartProps) {
  const { backendClient } = useGlobal();
  const { app, period, setPeriod, resetPeriod, env, consumerId, consumerGroupId, endpointGroupId } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    period,
  };
  const { data, isSuccess } = useQuery({
    queryKey: ["requestsPerMinuteChart", queryParams],
    queryFn: () => backendClient!.traffic.getRequestsPerMinuteChart(queryParams),
    enabled: !!backendClient && !!app && enabled,
  });

  let chart;
  if (isSuccess) {
    const color = rgba(getColor("primary"), 0.6);
    const chartOptions = merge(
      getChartOptions({
        labels: data.time_windows,
        title: displayTitle ? "Requests per minute" : undefined,
        setPeriod,
        resetPeriod,
      }),
      {
        interaction: { mode: "index" as const, intersect: false },
      },
    );
    const chartData: ChartData<"line"> = {
      labels: data.time_windows,
      datasets: [
        {
          label: "Requests per minute",
          data: data.requests_per_minute,
          borderWidth: 0,
          fill: true,
          borderColor: color,
          backgroundColor: color,
          pointStyle: false,
        },
      ],
    };
    chart = <Line data={chartData} options={chartOptions} />;
  }

  return <ChartContainer>{chart}</ChartContainer>;
}

export default memo(RequestsPerMinuteLineChart);
