import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";

import "./ModalHeaderEditButton.css";

type ModalHeaderEditButtonProps = {
  onClick?: () => void;
};

function ModalHeaderEditButton({ onClick }: ModalHeaderEditButtonProps) {
  if (!onClick) {
    return;
  }
  return (
    <div className="ModalHeaderEditButton">
      <Button variant="link" onClick={onClick}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </Button>
    </div>
  );
}

export default ModalHeaderEditButton;
