import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

type ConfirmationModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  confirmButtonVariant?: string;
  confirmButtonText?: string;
  dontAskAgainKey?: string;
  title: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
};

function ConfirmationModal({
  show,
  setShow,
  onConfirm,
  confirmButtonVariant = "primary",
  confirmButtonText = "Confirm",
  dontAskAgainKey,
  title,
  children,
}: ConfirmationModalProps) {
  const [dontAskAgain, setDontAskAgain] = React.useState(false);

  const onConfirmClick = () => {
    if (dontAskAgainKey && dontAskAgain) {
      localStorage.setItem("dontAskAgain:" + dontAskAgainKey, "true");
    }
    onConfirm();
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        {dontAskAgainKey && (
          <Form.Group controlId="dontAskAgain" className="mt-6">
            <Form.Check
              type="switch"
              checked={dontAskAgain}
              onChange={(e) => setDontAskAgain(e.target.checked)}
              label={<>Don't ask me to confirm this again</>}
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button variant={confirmButtonVariant} onClick={onConfirmClick}>
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
