import { faBellSlash, faClock, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight, faClock as faClockSolid, faEye, faStethoscope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import { useCallback, useState } from "react";
import { AlertRuleStateBadge } from "../Alerts";
import { GetAlertRuleResponse, ListAlertRulesResponseItem } from "../backend";
import AlertRuleLineChart from "../charts/AlertRuleLineChart";
import FilterBadge from "../components/FilterBadge";
import MetricChip from "../components/MetricChip";
import ModalSection from "../components/ModalSection";
import { useGlobal } from "../contexts/GlobalContext";
import AlertRuleHistoryTable from "../tables/AlertRuleHistoryTable";
import HealthChecksTable from "../tables/HealthChecksTable";
import { formatRelativeDateTime } from "../utils/datetime";
import "./AlertRuleModal.css";

const formatLastCheckValue = (
  metric: string | undefined,
  value: number | undefined | null,
  asUnit: string | undefined | null,
) => {
  if (!metric || value === null || value === undefined) {
    return "-";
  }
  if (metric === "apdex_score") {
    return value.toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 });
  } else if (asUnit === "%") {
    return (value * 100).toLocaleString(undefined, { maximumFractionDigits: 2 }) + " %";
  } else if (asUnit === "KB") {
    return (value / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + " KB";
  } else if (asUnit === "MB") {
    return (value / 1000000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + " MB";
  } else if (asUnit === "GB") {
    return (value / 1000000000).toLocaleString(undefined, { maximumFractionDigits: 1 }) + " GB";
  }
  return value.toLocaleString() + (asUnit ? " " + asUnit : "");
};

type AlertRuleModalTabProps = {
  alertRule?: ListAlertRulesResponseItem;
  query: UseQueryResult<GetAlertRuleResponse, Error>;
};

function AlertRuleModalDetailsTab({ alertRule, query }: AlertRuleModalTabProps) {
  const { apps } = useGlobal();

  const isMuted = query.isSuccess ? query.data.muted : alertRule?.muted;
  const healthCheckUrl =
    alertRule?.type === "health_check_failed"
      ? apps?.find((app) => app.id === alertRule?.app.id)?.envs.find((env) => env.id === alertRule?.app_env.id)
          ?.health_check_url
      : undefined;
  const lastCheckedAt = query.data?.last_checked_at ? DateTime.fromISO(query.data.last_checked_at) : undefined;
  const lastTriggeredAt = query.data?.last_triggered_at
    ? DateTime.fromISO(query.data.last_triggered_at)
    : alertRule?.last_triggered_at
      ? DateTime.fromISO(alertRule.last_triggered_at)
      : undefined;

  let description;
  if (alertRule?.description) {
    description = alertRule.description;
  } else if (alertRule?.type === "health_check_failed" && healthCheckUrl) {
    description = (
      <>
        Health checks monitor your application's availability by sending HTTP GET requests to the configured endpoint in
        1 minute intervals. This alert is triggered if the endpoint does not return a response with a 200 status code
        within 5 seconds.
      </>
    );
  } else if (alertRule?.type === "liveness_check_failed") {
    description = (
      <>
        Liveness checks monitor your application's uptime by listening for heartbeats sent by the Apitally client
        library. This alert is triggered if no heartbeat was received for 3 minutes.
      </>
    );
  }

  return (
    <>
      <div className="d-flex flex-wrap gap-2">
        {alertRule?.type === "custom" && query.data && (
          <>
            <FilterBadge
              icon="/icons/bell-ring-regular.svg"
              label="Trigger"
              value={`${query.data.metric_name} ${query.data.condition_formatted} ${query.data.threshold_formatted}`}
            />
            <FilterBadge icon={faClock} label="Period" value={`${query.data.period_minutes} min`} />
            {!query.data.cron_expression && (
              <FilterBadge
                icon="/icons/repeat-regular.svg"
                label="Check"
                value={`Every ${query.data.frequency_minutes} min`}
              />
            )}
            {query.data.cron_expression && (
              <FilterBadge icon="/icons/repeat-regular.svg" label="Check schedule" value={query.data.cron_expression} />
            )}
            {query.data.notify_triggered_after_minutes > 0 && (
              <FilterBadge
                icon={faEnvelope}
                label="Notify after"
                value={`${query.data.notify_triggered_after_minutes} min`}
              />
            )}
            {query.data.filter_consumer && (
              <FilterBadge icon="/icons/filter-regular.svg" label="Consumer" value={query.data.filter_consumer?.name} />
            )}
            {query.data.filter_consumer_group && (
              <FilterBadge
                icon="/icons/filter-regular.svg"
                label="Consumer group"
                value={query.data.filter_consumer_group?.name}
              />
            )}
            {query.data.filter_endpoint && (
              <FilterBadge icon="/icons/filter-regular.svg" label="Endpoint" value={query.data.filter_endpoint?.name} />
            )}
            {query.data.filter_endpoint_group && (
              <FilterBadge
                icon="/icons/filter-regular.svg"
                label="Endpoint group"
                value={query.data.filter_endpoint_group?.name}
              />
            )}
          </>
        )}
        {alertRule?.type === "health_check_failed" && healthCheckUrl && (
          <FilterBadge icon={faStethoscope} label="URL" value={healthCheckUrl} />
        )}
        {(alertRule?.type === "health_check_failed" || alertRule?.type === "liveness_check_failed") && (
          <FilterBadge icon="/icons/repeat-regular.svg" label="Check" value="Every 1 min" />
        )}
        {alertRule?.type === "health_check_failed" && (
          <FilterBadge icon={faEnvelope} label="Notify after" value="1 min" />
        )}
        {alertRule?.type === "liveness_check_failed" && (
          <FilterBadge icon={faEnvelope} label="Notify after" value="2 min" />
        )}
        {isMuted && <FilterBadge icon={faBellSlash} label="Notifications muted" noValue />}
      </div>

      {description && (
        <ModalSection title="Description" className="mt-6">
          <div className="border rounded px-3 py-2">{description}</div>
        </ModalSection>
      )}
      <ModalSection title="Summary" className="mt-6">
        <div className="mb-n4 me-n4">
          <MetricChip
            label="Last triggered"
            icon={faClock}
            value={formatRelativeDateTime(lastTriggeredAt, true, false) || "Never"}
            title={lastTriggeredAt?.toLocaleString(DateTime.DATETIME_FULL)}
          />
          {alertRule?.type === "custom" && (
            <>
              <MetricChip
                label="Last checked"
                icon={faClock}
                value={formatRelativeDateTime(lastCheckedAt, true, false) || "Never"}
                title={lastCheckedAt?.toLocaleString(DateTime.DATETIME_FULL)}
              />
              <MetricChip
                label="Value at last check"
                icon="/icons/gauge-simple-regular.svg"
                value={formatLastCheckValue(
                  query.data?.metric,
                  query.data?.last_check_value,
                  query.data?.threshold_unit,
                )}
              />
            </>
          )}
        </div>
      </ModalSection>
      {alertRule?.type === "custom" && alertRule?.id && (
        <ModalSection title="Timeline" titleExtra="last 24 hours" className="mt-6">
          <AlertRuleLineChart alertRule={query.data} />
        </ModalSection>
      )}
      {alertRule?.type === "health_check_failed" && (
        <ModalSection title="Health check history" titleExtra="last 24 hours" className="mt-6">
          <HealthChecksTable appId={alertRule.app.id} appEnv={alertRule.app_env.slug} />
        </ModalSection>
      )}
    </>
  );
}

function AlertRuleModalHistoryTab({ alertRule }: AlertRuleModalTabProps) {
  return (
    <ModalSection title="Trigger history" titleExtra="last 30 days">
      <AlertRuleHistoryTable alertRule={alertRule} />
    </ModalSection>
  );
}

type AlertRuleModalProps = {
  alertRule?: ListAlertRulesResponseItem;
  setAlertRule: (alertRule?: ListAlertRulesResponseItem) => void;
};

function AlertRuleModal({ alertRule, setAlertRule }: AlertRuleModalProps) {
  const { backendClient } = useGlobal();
  const [activeTab, setActiveTab] = useState("details");

  const queryParams = { alertRuleId: alertRule?.id || 0 };
  const query = useQuery({
    queryKey: ["alertRule", queryParams],
    queryFn: () => backendClient!.alerts.getAlertRule(queryParams),
    enabled: !!backendClient && alertRule?.type === "custom" && !!alertRule?.id,
    placeholderData: undefined,
    refetchInterval: 60000,
  });

  const selectTab = useCallback((key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  }, []);

  const tabs = [
    {
      eventKey: "details",
      title: "Details",
      icon: faEye,
      content: <AlertRuleModalDetailsTab alertRule={alertRule} query={query} />,
    },
    {
      eventKey: "history",
      title: "History",
      icon: faClockSolid,
      content: <AlertRuleModalHistoryTab alertRule={alertRule} query={query} />,
    },
  ];

  return (
    <Modal
      className="AlertRuleModal tabs"
      size="xl"
      show={alertRule !== undefined}
      onHide={() => setAlertRule(undefined)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="app-env">
            {alertRule?.app.name}
            <FontAwesomeIcon icon={faAngleRight} className="ms-2 me-2 text-very-muted small" />
            {alertRule?.app_env.name}
          </div>
          <div className="name-state">
            <span className="me-3">{alertRule?.name}</span>
            <span className="fs-5">
              <AlertRuleStateBadge state={query.data?.state || alertRule?.state} />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1 px-0">
        <Tab.Container activeKey={activeTab} onSelect={selectTab}>
          <Nav variant="tabs">
            {tabs.map((tab) => (
              <Nav.Item key={tab.eventKey} title={tab.title}>
                <Nav.Link eventKey={tab.eventKey}>
                  <FontAwesomeIcon icon={tab.icon} fixedWidth className="d-inline d-sm-none" />
                  <span className="d-none d-sm-inline">{tab.title}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            {tabs.map((tab) => (
              <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
}

export default AlertRuleModal;
