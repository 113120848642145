import { useQuery } from "@tanstack/react-query";
import { BarController, BarElement, Chart as ChartJS, LinearScale, Title, Tooltip, TooltipItem } from "chart.js";
import "chartjs-adapter-luxon";
import { memo } from "react";
import { Bar } from "react-chartjs-2";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getColor } from "../utils/colors";

ChartJS.register(LinearScale, BarController, BarElement, Title, Tooltip);

type ResponseTimeHistogramProps = {
  endpoint?: Endpoint;
};

function ResponseTimeHistogram({ endpoint }: ResponseTimeHistogramProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, consumerId, consumerGroupId } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    method: endpoint?.method,
    path: endpoint?.path,
    period,
  };
  const query = useQuery({
    queryKey: ["responseTimeHistogram", queryParams],
    queryFn: () => backendClient!.performance.getResponseTimeHistogram(queryParams),
    enabled: !!backendClient && !!app,
  });

  let chart;
  let footer;
  if (query.isSuccess) {
    const primaryColor = getColor("primary");
    const hyphen = " – "; // punctuation space and en dash
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      font: {
        family: "DM Sans",
      },
      scales: {
        x: {
          type: "linear" as const,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          display: true,
          suggestedMax: 250,
          ticks: {
            precision: 0,
            font: {
              family: "DM Sans",
            },
            callback: function (value: string | number) {
              return Number(value).toLocaleString() + " ms";
            },
          },
        },
        y: {
          grid: {
            display: true,
            color: "#faf9fb",
          },
          border: {
            display: false,
          },
          beginAtZero: true,
          ticks: {
            font: {
              family: "DM Sans",
            },
            color: "#adb5bd",
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        tooltip: {
          mode: "index" as const,
          callbacks: {
            title: function (context: TooltipItem<"bar">[]) {
              const x = context[0].parsed.x;
              return `${x.toLocaleString()}${hyphen}${(x + query.data.bin_size).toLocaleString()} ms`;
            },
            label: function (context: TooltipItem<"bar">) {
              const value = context.parsed.y.toLocaleString();
              return ` ${value} requests`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
    };
    const chartData = {
      labels: query.data.bins,
      datasets: [
        {
          data: query.data.counts,
          backgroundColor: primaryColor,
          hoverBackgroundColor: primaryColor,
          barPercentage: 1,
          categoryPercentage: 1,
          borderWidth: { left: 1, right: 0, top: 0, bottom: 0 },
          borderColor: "#ffffff00",
        },
      ],
    };
    chart = <Bar data={chartData} options={chartOptions} />;
    if (query.data.outliers.count > 1) {
      footer = (
        <div className="mt-2 text-center text-very-muted very-small">
          Outliers excluded: {query.data.outliers.count.toLocaleString()} requests with response times of{" "}
          {query.data.outliers.min?.toLocaleString()}
          {hyphen}
          {query.data.outliers.max?.toLocaleString()} ms
        </div>
      );
    } else if (query.data.outliers.count == 1) {
      footer = (
        <div className="mt-2 text-center text-very-muted very-small">
          Outlier excluded: 1 request with response time of {query.data.outliers.min?.toLocaleString()} ms
        </div>
      );
    }
  }

  return (
    <>
      <ChartContainer>{chart}</ChartContainer>
      {footer}
    </>
  );
}

export default memo(ResponseTimeHistogram);
