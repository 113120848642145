import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { ListAppsResponseItem } from "../backend";
import Method from "../components/Method";
import Path from "../components/Path";
import Tooltip from "../components/Tooltip";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";

type EndpointConfigModalProps = {
  app: ListAppsResponseItem;
  endpoint?: Endpoint;
  setEndpoint: React.Dispatch<React.SetStateAction<Endpoint | undefined>>;
  refresh: () => void;
};

interface EndpointConfigFormValues {
  excluded: boolean;
  target_response_time_ms: string;
}

function EndpointConfigModal({ app, endpoint, setEndpoint, refresh }: EndpointConfigModalProps) {
  const { backendClient, activeTeam, isTeamAdmin, isDemo } = useGlobal();
  const [initialFormValues, setInitialFormValues] = useState<EndpointConfigFormValues>();
  const queryClient = useQueryClient();
  const disableForm = !isTeamAdmin;

  const queryParams = { appId: app.id, method: endpoint?.method || "", path: endpoint?.path || "" };
  const query = useQuery({
    queryKey: ["endpointConfig", queryParams],
    queryFn: () => backendClient!.endpoints.getEndpointConfig(queryParams),
    enabled: !!backendClient && !!endpoint,
    placeholderData: undefined,
  });

  useEffect(() => {
    if (endpoint && query.data) {
      setInitialFormValues({
        excluded: query.data.excluded,
        target_response_time_ms: query.data.target_response_time_ms?.toString() || "",
      });
    } else {
      setInitialFormValues(undefined);
    }
  }, [endpoint, query.data]);

  const updateConfig = async (values: EndpointConfigFormValues) => {
    if (backendClient && app && endpoint) {
      const promise = backendClient.endpoints.updateEndpointConfig({
        appId: app.id,
        requestBody: {
          method: endpoint.method,
          path: endpoint.path,
          excluded: values.excluded,
          target_response_time_ms: parseInt(values.target_response_time_ms) || null,
        },
      });
      toast.promise(promise, {
        pending: "Updating endpoint config...",
        success: "Endpoint config updated!",
        error: "Failed to update endpoint config.",
      });
      await promise;
    }
  };

  if (!initialFormValues) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await updateConfig(values);
          // Invalidate performance related queries in case the response time threshold has changed
          // and requests sparkline queries in case the endpoint has been excluded
          [
            "performanceMetrics",
            "performanceEndpointsTable",
            "apdexScoreChart",
            "consumerRequestsSparklines",
            "endpointGroupRequestsSparklines",
          ].forEach((key) => {
            queryClient.invalidateQueries({ queryKey: [key, { appId: app.id }] });
          });
          queryClient.invalidateQueries({ queryKey: ["appRequestsSparklines", { teamId: activeTeam?.id }] });
          setEndpoint(undefined);
          refresh();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, resetForm }) => (
        <Modal show={endpoint !== undefined} onHide={() => setEndpoint(undefined)} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Endpoint settings</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <BootstrapForm.Group controlId="formEndpoint">
                <BootstrapForm.Label>Endpoint</BootstrapForm.Label>
                <div className="text-nowrap overflow-hidden">
                  <Method method={endpoint?.method} /> <Path path={endpoint?.path} />
                </div>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formApdex" className="mt-4">
                <BootstrapForm.Label>Response time threshold</BootstrapForm.Label>
                <InputGroup>
                  <Field name="target_response_time_ms">
                    {({ field }: FieldProps<number>) => (
                      <BootstrapForm.Control
                        type="number"
                        min={10}
                        max={60000}
                        step={10}
                        placeholder={app.target_response_time_ms.toString()}
                        disabled={disableForm}
                        isInvalid={!!errors.target_response_time_ms && !!touched.target_response_time_ms}
                        {...field}
                      />
                    )}
                  </Field>
                  <InputGroup.Text className="text-secondary">ms</InputGroup.Text>
                </InputGroup>
                <BootstrapForm.Text muted>
                  This is used to calculate Apdex scores. Response times below or equal to the configured threshold are
                  considered satisfactory.
                </BootstrapForm.Text>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formExcluded" className="mt-4">
                <BootstrapForm.Label>Exclude requests</BootstrapForm.Label>
                <Field type="checkbox" name="excluded">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Check type="switch" disabled={disableForm} {...field} />
                  )}
                </Field>
                <BootstrapForm.Text muted>
                  Enabling this will exclude requests to this endpoint from all traffic and performance metrics.
                </BootstrapForm.Text>
              </BootstrapForm.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setEndpoint(undefined)}>
                Discard
              </Button>
              <Tooltip condition={isDemo && !isTeamAdmin} tooltip="Not allowed in demo" placement="bottom">
                <Button onClick={() => handleSubmit()} disabled={isSubmitting || disableForm}>
                  Submit
                </Button>
              </Tooltip>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default EndpointConfigModal;
