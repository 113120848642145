import { useAuth0 } from "@auth0/auth0-react";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { ApiError } from "../backend";
import { useGlobal } from "../contexts/GlobalContext";

type AccountDeleteModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

interface AccountDeleteFormValues {
  feedback: string;
  email: string;
}

function AccountDeleteModal({ show, setShow }: AccountDeleteModalProps) {
  const { user, logout } = useAuth0();
  const { backendClient, teams } = useGlobal();
  const [deleteFailed, setDeleteFailed] = useState(false);
  const [submittedFeedback, setSubmittedFeedback] = useState("");
  const deleteAppsFirst = teams?.some((team) => team.role === "owner" && team.users_count === 1 && team.apps_count > 0);

  const validate = (values: AccountDeleteFormValues) => {
    const errors: Partial<AccountDeleteFormValues> = {};
    if (values.email.trim() !== user?.email) {
      errors.email = "Email does not match";
    }
    return errors;
  };

  const submitFeedback = async (feedback: string) => {
    if (backendClient && feedback && feedback !== submittedFeedback) {
      const promise = backendClient.users.submitFeedback({ requestBody: { feedback } });
      toast.promise(promise, {
        pending: "Submitting feedback...",
        success: "Thank you for your feedback!",
        error: "Failed to submit feedback.",
      });
      await promise;
      setSubmittedFeedback(feedback);
    }
  };

  const deleteAccount = async () => {
    if (backendClient) {
      const promise = backendClient.users.deleteUser();
      toast.promise(promise, {
        pending: "Deleting account...",
        success: "Account deleted!",
        error: "Failed to delete account.",
      });
      await promise;
    }
  };

  return (
    <Formik
      initialValues={{ feedback: "", email: "" }}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        await submitFeedback(values.feedback.trim());
        try {
          await deleteAccount();
          await logout();
        } catch (e) {
          if (e instanceof ApiError && (e.status === 403 || e.status === 409)) {
            setDeleteFailed(true);
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, resetForm }) => (
        <Modal show={show} onHide={() => setShow(false)} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Delete account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteAppsFirst && (
              <Alert variant="danger">
                You cannot delete your account while your team has apps. Please delete your apps first.
              </Alert>
            )}
            {deleteFailed && (
              <Alert variant="danger">
                <p>We were unable to delete your account.</p>
                <p>
                  If you are an owner of a team with multiple users that doesn't have any other owners, assign the owner
                  role to another user first.
                </p>
                <p className="mb-0">
                  If you are the billing contact for a team on a paid plan, please contact support.
                </p>
              </Alert>
            )}
            <p>
              We're sad to see you go. If there is anything we could do to make Apitally more useful to you, please let
              us know below. Your feedback is invaluable to us.
            </p>
            <Form onSubmit={handleSubmit}>
              <BootstrapForm.Group controlId="formResponse">
                <BootstrapForm.Label>What can we do to improve Apitally?</BootstrapForm.Label>
                <Field name="feedback">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      as="textarea"
                      placeholder="Your feedback goes here..."
                      rows={3}
                      maxLength={4096}
                      autoFocus
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.feedback}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formName" className="mt-4">
                <BootstrapForm.Label className="fw-normal">
                  Enter your email address <strong>{user?.email}</strong> to confirm
                </BootstrapForm.Label>
                <Field name="email">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder={user?.email}
                      autoComplete="off"
                      isInvalid={!!errors.email && !!touched.email}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.email}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleSubmit()}
              disabled={isSubmitting || deleteAppsFirst || deleteFailed}
            >
              Delete your account
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default AccountDeleteModal;
