import { faBell, faBellSlash, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faBellSlash as faBellSlashSolid,
  faBell as faBellSolid,
  faCog,
  faEye,
  faEyeSlash,
  faFilterCircleXmark,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ListAlertRulesResponseItem } from "./backend";
import CustomIcon from "./components/CustomIcon";
import CustomDropdown from "./components/Dropdown";
import FilterBadge from "./components/FilterBadge";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import TableCard from "./components/TableCard";
import TableCellDropdown from "./components/TableCellDropdown";
import TeaserCard from "./components/TeaserCard";
import Tooltip from "./components/Tooltip";
import { useConfirmation } from "./contexts/ConfirmationContext";
import { useGlobal } from "./contexts/GlobalContext";
import AlertRuleFormModal from "./modals/AlertRuleFormModal";
import AlertRuleModal from "./modals/AlertRuleModal";
import { formatRelativeDateTime } from "./utils/datetime";

type AlertRuleStateBadgeProps = {
  state?: string;
};

export function AlertRuleStateBadge({ state }: AlertRuleStateBadgeProps) {
  if (!state) {
    return;
  }
  return (
    <Badge
      className={classNames({
        "outline-primary": state === "ok",
        "outline-danger": state === "triggered",
        "outline-secondary": state !== "ok" && state !== "triggered",
      })}
    >
      {state == "ok" ? "OK" : capitalize(state?.replace("_", " "))}
    </Badge>
  );
}

function AlertsTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <PageHeader buttons={pageHeaderButtons}>
        <>Alerts</>
        <span className="text-muted">No apps set up yet</span>
      </PageHeader>
      <TeaserCard icon={faBellSolid}>
        <h2>Get notified immediately when things go wrong</h2>
        <p className="mt-4">
          Here you can create custom alerts based on API requests, errors, and performance metrics. Various filtering
          and check scheduling options offer maximum flexibility. You can get notified via email, Slack or Microsoft
          Teams when an alert is triggered.
        </p>
        <p>Below are some examples for alerts you could set up:</p>
        <ul>
          <li>A specific endpoint or endpoint group received more than 500 requests in an hour</li>
          <li>An API consumer has made less than 100 requests in the last 24 hours (except weekends)</li>
          <li>The median response time for an endpoint is higher than 1 second</li>
          <li>The Apdex score for the last hour of requests has dropped below 0.93</li>
          <li>More than 1 GB of data was received in the last 5 minutes</li>
          <li>The error rate has been higher than 5% in the last 15 minutes</li>
          <li>There have been any server errors</li>
        </ul>
      </TeaserCard>
    </MainLayout>
  );
}

function Alerts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeTeam, apps, backendClient, refreshTeamPlan, refreshAlertCounts, isTeamAdmin, isDemo } = useGlobal();
  const [appId, setAppId] = useState<number | undefined>(parseInt(searchParams.get("app") || "0", 10) || undefined);
  const [formModalAlertRuleId, setFormModalAlertRuleId] = useState<number>();
  const [detailsModalAlertRule, setDetailsModalAlertRule] = useState<ListAlertRulesResponseItem>();
  const [showDisabled, setShowDisabled] = useState(false);
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;
  const app = apps?.find((app) => app.id === appId);
  const navigate = useNavigate();
  const { maybeConfirm } = useConfirmation();

  useEffect(() => {
    document.title = `Alerts - Apitally`;
  }, []);

  useEffect(() => {
    if (appId?.toString() !== searchParams.get("app")) {
      setSearchParams((searchParams) => {
        if (appId) {
          searchParams.set("app", appId.toString());
        } else {
          searchParams.delete("app");
        }
        return searchParams;
      });
    }
  }, [appId]);

  const queryParams = { teamId: activeTeam?.id || 0 };
  const query = useQuery({
    queryKey: ["alertRules", queryParams],
    queryFn: () => backendClient!.alerts.listAlertRules(queryParams),
    enabled: !!backendClient && !!activeTeam,
    refetchInterval: 60000,
  });

  useEffect(() => {
    if (query.data) {
      if (searchParams.has("alert_rule")) {
        const alertRuleId = parseInt(searchParams.get("alert_rule") || "0", 10);
        const alertRule = query.data.find((alertRule) => alertRule.id === alertRuleId);
        setDetailsModalAlertRule(alertRule);
      } else if (searchParams.has("app_env") && searchParams.has("alert_type")) {
        const appEnvId = parseInt(searchParams.get("app_env") || "0", 10);
        const alertType = searchParams.get("alert_type") || "";
        const alertRule = query.data.find(
          (alertRule) => alertRule.type === alertType && alertRule.app_env.id === appEnvId,
        );
        setDetailsModalAlertRule(alertRule);
      }
    }
  }, [searchParams, query.data]);

  const toggleMuted = async (alertRule: ListAlertRulesResponseItem, muted: boolean) => {
    if (!backendClient) {
      return;
    }
    let promise;
    if (alertRule.type === "custom" && alertRule.id) {
      promise = backendClient.alerts.updateAlertRule({ alertRuleId: alertRule.id, requestBody: { muted } });
    } else if (alertRule.type === "liveness_check_failed" || alertRule.type === "health_check_failed") {
      const requestBody =
        alertRule.type === "health_check_failed" ? { health_check_muted: muted } : { liveness_check_muted: muted };
      promise = backendClient.apps.updateAppEnv({
        appId: alertRule.app.id,
        appEnvId: alertRule.app_env.id,
        requestBody,
      });
    } else {
      return;
    }
    if (muted) {
      toast.promise(promise, {
        pending: "Muting alert notifications...",
        success: "Alert notifications muted!",
        error: "Failed to mute alert notifications.",
      });
    } else {
      toast.promise(promise, {
        pending: "Unmuting alert notifications...",
        success: "Alert notifications unmuted!",
        error: "Failed to unmute alert notifications.",
      });
    }
    await promise;
    query.refetch();
    refreshAlertCounts();
  };

  const deleteAlertRule = async (alertRule: ListAlertRulesResponseItem) => {
    if (!backendClient) {
      return;
    }
    if (alertRule.type === "custom" && alertRule.id) {
      const promise = backendClient.alerts.deleteAlertRule({ alertRuleId: alertRule.id });
      toast.promise(promise, {
        pending: "Deleting alert...",
        success: "Alert deleted!",
        error: "Failed to delete alert.",
      });
      await promise;
      query.refetch();
      refreshTeamPlan();
      refreshAlertCounts();
    }
  };

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <AlertsTeaser />;
  }

  const allAlertRulesDisabled = query.data?.every((alertRule) => alertRule.state === "disabled");
  const someAlertRulesDisabled = query.data?.some((alertRule) => alertRule.state === "disabled");
  const alertRuleRows = query.data
    ?.filter((alertRule) => !appId || alertRule.app.id === appId)
    .filter((alertRule) => showDisabled || alertRule.state !== "disabled" || allAlertRulesDisabled)
    .map((alertRule) => {
      const lastTriggeredAt = DateTime.fromISO(alertRule.last_triggered_at || "");
      return (
        <tr
          key={`${alertRule.app_env.id}:${alertRule.type}:${alertRule.id || 0}`}
          className="cursor-pointer"
          onClick={(e) => {
            const cell = (e.target as HTMLElement).closest("td");
            if (!cell?.classList.contains("TableCellDropdown")) {
              setDetailsModalAlertRule(alertRule);
            }
          }}
        >
          <td
            style={{ width: 40 }}
            className={classNames("ps-3", {
              "text-very-muted": alertRule.state !== "triggered",
              "text-danger": alertRule.state === "triggered",
            })}
          >
            {alertRule.state === "triggered" && !alertRule.muted ? (
              <CustomIcon fixedWidth src="/icons/bell-ring-regular.svg" />
            ) : (
              <Tooltip
                condition={alertRule.state === "disabled" || alertRule.muted}
                tooltip={alertRule.muted ? "Notifications muted" : "Checks disabled"}
                placement="left"
              >
                <FontAwesomeIcon
                  fixedWidth
                  icon={alertRule.state === "disabled" || alertRule.muted ? faBellSlash : faBell}
                />
              </Tooltip>
            )}
          </td>
          <td>
            <div className="text-nowrap">{alertRule.name}</div>
            <div className="small text-muted text-nowrap">
              {(alertRule.type === "health_check_failed" || alertRule.type === "liveness_check_failed") && <>Uptime</>}
              {alertRule.type === "custom" && (
                <>
                  {alertRule.trigger}
                  {alertRule.filtered && (
                    <>
                      <span className="mx-1" style={{ opacity: 0.5 }}>
                        /
                      </span>
                      Filtered
                    </>
                  )}
                </>
              )}
            </div>
          </td>
          <td style={{ minWidth: 80 }}>
            <AlertRuleStateBadge state={alertRule.state} />
          </td>
          <td>
            <span title={lastTriggeredAt.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
              <FontAwesomeIcon icon={faClock} className="me-2 text-secondary" />
              {formatRelativeDateTime(lastTriggeredAt) || "Never"}
            </span>
          </td>
          <td>
            <div className="text-nowrap">{alertRule.app.name}</div>
            <div className="small text-muted text-nowrap">{alertRule.app_env.name}</div>
          </td>
          <TableCellDropdown>
            <Dropdown.Item as="button" onClick={() => setDetailsModalAlertRule(alertRule)}>
              <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
              Alert details
            </Dropdown.Item>
            {alertRule.type === "custom" && alertRule.id && isTeamAdminOrDemo && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={() => setFormModalAlertRuleId(alertRule.id || undefined)}>
                  <FontAwesomeIcon icon={faPenToSquare} fixedWidth className="text-secondary" />
                  Edit alert
                </Dropdown.Item>
                {alertRule.muted ? (
                  <Dropdown.Item as="button" disabled={!isTeamAdmin} onClick={() => toggleMuted(alertRule, false)}>
                    <FontAwesomeIcon icon={faBellSolid} fixedWidth className="text-secondary" />
                    Unmute notifications
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item as="button" disabled={!isTeamAdmin} onClick={() => toggleMuted(alertRule, true)}>
                    <FontAwesomeIcon icon={faBellSlashSolid} fixedWidth className="text-secondary" />
                    Mute notifications
                  </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  className="text-danger"
                  disabled={!isTeamAdmin}
                  onClick={() =>
                    maybeConfirm({
                      title: "Delete alert",
                      body: (
                        <p>
                          Are you sure you want to delete the alert <b>{alertRule.name}</b>?
                        </p>
                      ),
                      onConfirm: () => deleteAlertRule(alertRule),
                      confirmButtonVariant: "danger",
                      confirmButtonText: "Delete",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                  Delete alert
                </Dropdown.Item>
              </>
            )}
            {(alertRule.type === "liveness_check_failed" || alertRule.type === "health_check_failed") &&
              isTeamAdminOrDemo && (
                <>
                  <Dropdown.Divider />
                  {alertRule.type === "health_check_failed" && (
                    <Dropdown.Item
                      as="button"
                      disabled={!isTeamAdmin}
                      onClick={() => navigate(`/uptime?configureHealthChecks=${alertRule.app.id}`)}
                    >
                      <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" />
                      Configure health checks
                    </Dropdown.Item>
                  )}
                  {alertRule.type === "liveness_check_failed" && (
                    <Dropdown.Item
                      as="button"
                      disabled={!isTeamAdmin}
                      onClick={() => navigate(`/apps?editApp=${alertRule.app.id}`)}
                    >
                      <FontAwesomeIcon icon={faCog} fixedWidth className="text-secondary" />
                      Configure uptime alerts
                    </Dropdown.Item>
                  )}
                  {alertRule.state !== "disabled" && (
                    <>
                      {alertRule.muted ? (
                        <Dropdown.Item
                          as="button"
                          disabled={!isTeamAdmin}
                          onClick={() => toggleMuted(alertRule, false)}
                        >
                          <FontAwesomeIcon icon={faBellSolid} fixedWidth className="text-secondary" />
                          Unmute notifications
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item as="button" disabled={!isTeamAdmin} onClick={() => toggleMuted(alertRule, true)}>
                          <FontAwesomeIcon icon={faBellSlashSolid} fixedWidth className="text-secondary" />
                          Mute notifications
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </>
              )}
          </TableCellDropdown>
        </tr>
      );
    });

  const pageHeaderButtons = [
    <CustomDropdown
      key="app-dropdown"
      title={app?.name || "All apps"}
      icon="/icons/filter-regular.svg"
      align="end"
      className="ms-auto"
      onSelect={(appId: any) => appId !== null && setAppId(parseInt(appId, 10) || undefined)}
    >
      <Dropdown.Menu>
        <Dropdown.Item key={0} eventKey={0} active={!appId}>
          All apps
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Filter by app</Dropdown.Header>
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
          {apps?.map((app) => (
            <Dropdown.Item key={app.id} eventKey={app.id} active={app.id === appId}>
              {app.name}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </CustomDropdown>,
  ];
  if (isTeamAdminOrDemo) {
    pageHeaderButtons.push(
      <Button
        key="create-alert-rule-button"
        variant="primary"
        className="float-end"
        onClick={() => setFormModalAlertRuleId(0)}
      >
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Create alert
      </Button>,
    );
  }
  const pageHeaderDropdown =
    isTeamAdminOrDemo || appId ? (
      <Dropdown.Menu>
        {isTeamAdminOrDemo && (
          <Dropdown.Item as="button" onClick={() => setFormModalAlertRuleId(0)}>
            <FontAwesomeIcon icon={faPlus} fixedWidth />
            Create alert
          </Dropdown.Item>
        )}
        {appId && (
          <Dropdown.Item as="button" onClick={() => setAppId(undefined)}>
            <FontAwesomeIcon icon={faFilterCircleXmark} fixedWidth />
            Clear filter
          </Dropdown.Item>
        )}
        <Dropdown.Divider />
        <Dropdown.Header>Filter by app</Dropdown.Header>
        {apps?.map((app) => (
          <Dropdown.Item key={app.id} active={app.id === appId} onClick={() => setAppId(app.id)}>
            {app.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    ) : undefined;
  const tableFooter =
    someAlertRulesDisabled && !allAlertRulesDisabled ? (
      <div className="small footer-actions">
        {!showDisabled && (
          <Button variant="link" onClick={() => setShowDisabled(true)}>
            <FontAwesomeIcon icon={faEye} className="me-icon" />
            Show disabled alerts
          </Button>
        )}
        {showDisabled && (
          <Button variant="link" onClick={() => setShowDisabled(false)}>
            <FontAwesomeIcon icon={faEyeSlash} className="me-icon" />
            Hide disabled alerts
          </Button>
        )}
      </div>
    ) : undefined;

  return (
    <MainLayout>
      <div className="Alerts">
        <AlertRuleModal
          alertRule={detailsModalAlertRule}
          setAlertRule={(alertRule) => {
            setDetailsModalAlertRule(alertRule);
            if (!alertRule) {
              setSearchParams((searchParams) => {
                searchParams.delete("app_env");
                searchParams.delete("alert_rule");
                searchParams.delete("alert_type");
                return searchParams;
              });
            }
          }}
        />
        <AlertRuleFormModal alertRuleId={formModalAlertRuleId} setAlertRuleId={setFormModalAlertRuleId} />
        <PageHeader breakpoint="md" buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          Alerts
        </PageHeader>
        {appId && (
          <div className="small d-md-none mb-4">
            <FilterBadge
              icon="/icons/filter-regular.svg"
              label="App"
              value={apps?.find((app) => app.id === appId)?.name}
              removeFilter={() => setAppId(undefined)}
            />
          </div>
        )}
        <TableCard
          className="AlertRulesTable"
          responsive
          hover={alertRuleRows && alertRuleRows.length > 0}
          footer={tableFooter}
        >
          <thead>
            <tr>
              <th></th>
              <th>Alert name</th>
              <th>State</th>
              <th>Last triggered</th>
              <th>App</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {alertRuleRows}
            {query.isPending && (
              <Placeholder as="tr" animation="glow">
                <td style={{ width: 40 }}></td>
                <td>
                  <div>
                    <Placeholder xs={6} />
                  </div>
                  <div className="small text-body-secondary">
                    <Placeholder xs={4} />
                  </div>
                </td>
                <td className="text-primary">
                  <Placeholder xs={4} />
                </td>
                <td>
                  <Placeholder xs={6} />
                </td>
                <td>
                  <div>
                    <Placeholder xs={6} />
                  </div>
                  <div className="small text-body-secondary">
                    <Placeholder xs={2} />
                  </div>
                </td>
                <td style={{ width: 40 }}></td>
              </Placeholder>
            )}
            {query.isSuccess && alertRuleRows?.length === 0 && (
              <tr>
                <td colSpan={6} className="text-center py-6 px-4">
                  You haven't configured any alerts{appId ? " for this app" : ""} yet.
                </td>
              </tr>
            )}
          </tbody>
        </TableCard>
      </div>
    </MainLayout>
  );
}

export default Alerts;
