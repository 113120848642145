import { useQuery } from "@tanstack/react-query";
import {
  ChartData,
  Chart as ChartJS,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tick,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { DateTime } from "luxon";
import { memo } from "react";
import { Line } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import { GetAlertRuleResponse } from "../backend";
import ChartContainer from "../components/ChartContainer";
import { useGlobal } from "../contexts/GlobalContext";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";
import { formatDataSize, formatResponseTime } from "../utils/numbers";
import { formatPeriodStep } from "../utils/period";

ChartJS.register(Filler, LinearScale, TimeSeriesScale, LineController, LineElement, PointElement, Title, Tooltip);

type AlertRuleLineChartProps = {
  alertRule?: GetAlertRuleResponse;
};

function AlertRuleLineChart({ alertRule }: AlertRuleLineChartProps) {
  const { backendClient } = useGlobal();

  const queryParams = {
    alertRuleId: alertRule?.id || 0,
  };
  const query = useQuery({
    queryKey: ["alertRuleTimelineChart", queryParams],
    queryFn: () => backendClient!.alerts.getAlertRuleTimelineChart(queryParams),
    enabled: !!backendClient && !!alertRule?.id,
    placeholderData: undefined,
    refetchInterval: 60000,
  });

  let chart;
  if (alertRule && query.isSuccess) {
    const dangerColor = getColor("danger");
    const secondaryColor = getColor("secondary");
    let additionalChartOptions = {};
    let tooltipUnit;
    if (
      alertRule.metric === "data_transferred" ||
      alertRule.metric === "data_received" ||
      alertRule.metric === "data_sent"
    ) {
      additionalChartOptions = {
        scales: {
          y: {
            ticks: {
              callback: function (value: string | number, index: number, ticks: Tick[]) {
                return formatDataSize(Number(value) / 1000, (ticks.at(-1)?.value || 0) / 1000);
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: TooltipItem<"line">) {
                const value = formatDataSize(context.parsed.y / 1000);
                return ` ${context.dataset.label}: ${value}`;
              },
            },
          },
        },
      };
    } else if (alertRule.metric === "error_rate") {
      additionalChartOptions = {
        scales: {
          y: {
            ticks: {
              format: {
                style: "percent" as const,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              },
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: TooltipItem<"line">) {
                const value = context.parsed.y.toLocaleString(undefined, {
                  style: "percent",
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 2,
                });
                return ` ${context.dataset.label}: ${value}`;
              },
            },
          },
        },
      };
    } else if (alertRule.metric.startsWith("response_time_")) {
      additionalChartOptions = {
        scales: {
          y: {
            ticks: {
              callback: function (value: string | number, index: number, ticks: Tick[]) {
                return formatResponseTime(Number(value), ticks.at(-1)?.value);
              },
            },
          },
        },
      };
      tooltipUnit = "ms";
    } else if (alertRule.metric === "apdex_score") {
      additionalChartOptions = {
        scales: {
          y: {
            suggestedMax: 1,
          },
        },
      };
    }
    const chartOptions = merge.withOptions(
      { allowUndefinedOverrides: false },
      getChartOptions({
        labels: query.data.timestamps,
        tooltipUnit,
      }),
      {
        scales: {
          y: {
            suggestedMax: alertRule.threshold_converted * 1.1,
          },
        },
        tooltips: { intersect: false },
        interaction: { mode: "index" as const, intersect: false },
        plugins: {
          tooltip: {
            position: "nearest" as const,
            callbacks: {
              title: function (context: TooltipItem<"line">[]) {
                const start = DateTime.fromMillis(context[0].parsed.x);
                return formatPeriodStep(start, start);
              },
            },
          },
          annotation: {
            annotations: {
              targetLine: {
                type: "line" as const,
                yMin: alertRule.threshold_converted,
                yMax: alertRule.threshold_converted,
                borderColor: dangerColor,
                borderWidth: 0.75,
                label: {
                  content: "Threshold",
                  display: true,
                  font: {
                    family: "DM Sans",
                    weight: "normal" as const,
                    size: 10,
                  },
                  color: dangerColor,
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  position: "start" as const,
                  padding: {
                    top: 1,
                    bottom: 0,
                    left: 3,
                    right: 3,
                  },
                  borderRadius: 3,
                  yAdjust: alertRule.threshold_converted == 0 ? -2 : -10,
                },
              },
            },
          },
        },
      },
      additionalChartOptions,
    );
    const chartData: ChartData<"line"> = {
      labels: query.data.timestamps,
      datasets: [
        {
          label: alertRule.metric_name,
          data: query.data.values,
          borderColor: secondaryColor,
          backgroundColor: secondaryColor,
          pointStyle: false,
          borderWidth: 2,
        },
      ],
    };
    chart = <Line data={chartData} options={chartOptions} />;
  }

  return <ChartContainer>{chart}</ChartContainer>;
}

export default memo(AlertRuleLineChart);
