import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGlobal } from "../contexts/GlobalContext";

function useAppSlug() {
  const { appSlug } = useParams();
  const { apps, setActiveApp } = useGlobal();
  const app = apps?.find((app) => app.slug === appSlug);

  useEffect(() => {
    if (app) {
      setActiveApp(app);
    }
  }, [app]);

  return {
    appSlug,
    app,
  };
}

export default useAppSlug;
