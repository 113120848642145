import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { AppEnvItem, ListAppsResponseItem } from "../backend";
import useAppSlug from "../hooks/useAppSlug";
import { getInitialPeriod, isCustomPeriod, validatePeriod } from "../utils/period";

type FilterContextType = {
  app?: ListAppsResponseItem;
  appSlug?: string;
  period: string;
  setPeriod: (period: string) => void;
  resetPeriod: () => void;
  env?: AppEnvItem;
  setEnv: (env?: AppEnvItem) => void;
  consumerId?: number;
  setConsumerId: (consumerId?: number) => void;
  consumerGroupId?: number;
  setConsumerGroupId: (consumerGroupId?: number) => void;
  endpointGroupId?: number;
  setEndpointGroupId: (endpointGroupId?: number) => void;
};

const FilterContext = React.createContext<FilterContextType | undefined>(undefined);

export function useFilters() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FilterContextProvider");
  }
  return context;
}

type FilterContextProviderProps = {
  disableConsumer?: boolean;
  disableConsumerGroup?: boolean;
  disableEndpointGroup?: boolean;
  children: React.ReactNode;
};

export function FilterContextProvider({
  disableConsumer = false,
  disableConsumerGroup = false,
  disableEndpointGroup = false,
  children,
}: FilterContextProviderProps) {
  const { app, appSlug } = useAppSlug();
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParam = (key: string, value?: string) => {
    setSearchParams(
      (searchParams) => {
        if (value) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
        return searchParams;
      },
      { replace: true },
    );
  };
  const setSessionStorage = (key: string, value?: string) => {
    if (value) {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.removeItem(key);
    }
  };
  const setLocalStorage = (key: string, value?: string) => {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  };

  // Period - localStorage for default period, sessionStorage for custom period
  const storePeriod = (period: string) => {
    if (isCustomPeriod(period)) {
      sessionStorage.setItem("customPeriod", period);
    } else {
      localStorage.setItem("period", period);
      sessionStorage.removeItem("customPeriod");
    }
  };
  const setPeriod = (period: string) => {
    setSearchParam("period", period);
    storePeriod(period);
  };
  const periodSearchParam = searchParams.get("period");
  const period = periodSearchParam && validatePeriod(periodSearchParam) ? periodSearchParam : getInitialPeriod();
  const resetPeriod = () => {
    if (isCustomPeriod(period)) {
      setPeriod(getInitialPeriod({ allowCustom: false }));
    }
  };

  useEffect(() => {
    storePeriod(period);
  }, [period]);

  // App env - localStorage
  const envSlugSearchParam = searchParams.get("env");
  const envSlugLocalStorageKey = `env:${app?.id}`;
  const envSlugLocalStorage = localStorage.getItem(envSlugLocalStorageKey);
  const envSlug = envSlugSearchParam || envSlugLocalStorage || undefined;
  const env = app?.envs.find((env) => env.slug === envSlug);
  const setEnv = (env?: AppEnvItem) => {
    setSearchParam("env", env?.slug);
    setLocalStorage(envSlugLocalStorageKey, env?.active ? env.slug : undefined);
  };

  useEffect(() => {
    setLocalStorage(envSlugLocalStorageKey, env?.active ? env.slug : undefined);
  }, [env]);

  // Consumer - sessionStorage
  const consumerSearchParam = parseInt(searchParams.get("consumer") || "0");
  const consumerSessionStorageKey = `consumer:${app?.id}`;
  const consumerSessionStorage = parseInt(sessionStorage.getItem(consumerSessionStorageKey) || "0");
  const consumerId = !disableConsumer ? consumerSearchParam || consumerSessionStorage || undefined : undefined;
  const setConsumerId = (consumerId?: number) => {
    setSearchParam("consumer", consumerId?.toString());
    setSessionStorage(consumerSessionStorageKey, consumerId?.toString());
  };

  useEffect(() => {
    if (!disableConsumer) {
      setSessionStorage(consumerSessionStorageKey, consumerId?.toString());
    }
  }, [consumerId]);

  // Consumer group - sessionStorage
  const consumerGroupSearchParam = parseInt(searchParams.get("consumer_group") || "0");
  const consumerGroupSessionStorageKey = `consumerGroup:${app?.id}`;
  const consumerGroupSessionStorage = parseInt(sessionStorage.getItem(consumerGroupSessionStorageKey) || "0");
  const consumerGroupId = !disableConsumerGroup
    ? consumerGroupSearchParam || consumerGroupSessionStorage || undefined
    : undefined;
  const setConsumerGroupId = (consumerGroupId?: number) => {
    setSearchParam("consumer_group", consumerGroupId?.toString());
    setSessionStorage(consumerGroupSessionStorageKey, consumerGroupId?.toString());
  };

  useEffect(() => {
    if (!disableConsumerGroup) {
      setSessionStorage(consumerGroupSessionStorageKey, consumerGroupId?.toString());
    }
  }, [consumerGroupId]);

  // Endpoint group - sessionStorage
  const endpointGroupSearchParam = parseInt(searchParams.get("endpoint_group") || "0");
  const endpointGroupSessionStorageKey = `endpointGroup:${app?.id}`;
  const endpointGroupSessionStorage = parseInt(sessionStorage.getItem(endpointGroupSessionStorageKey) || "0");
  const endpointGroupId = !disableEndpointGroup
    ? endpointGroupSearchParam || endpointGroupSessionStorage || undefined
    : undefined;
  const setEndpointGroupId = (endpointGroupId?: number) => {
    setSearchParam("endpoint_group", endpointGroupId?.toString());
    setSessionStorage(endpointGroupSessionStorageKey, endpointGroupId?.toString());
  };

  useEffect(() => {
    if (!disableEndpointGroup) {
      setSessionStorage(endpointGroupSessionStorageKey, endpointGroupId?.toString());
    }
  }, [endpointGroupId]);

  useEffect(() => {
    if (app) {
      // Ensure query params are in sync with app state when page loads or app changes
      setPeriod(period);
      setEnv(env);
      if (!disableConsumer) {
        setConsumerId(consumerId);
      }
      if (!disableConsumerGroup) {
        setConsumerGroupId(consumerGroupId);
      }
      if (!disableEndpointGroup) {
        setEndpointGroupId(endpointGroupId);
      }
    }
  }, [app]);

  return (
    <FilterContext.Provider
      value={{
        app,
        appSlug,
        period,
        setPeriod,
        resetPeriod,
        env,
        setEnv,
        consumerId,
        setConsumerId,
        consumerGroupId,
        setConsumerGroupId,
        endpointGroupId,
        setEndpointGroupId,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export default FilterContextProvider;
