import { DateTime } from "luxon";

import changelog from "./changelog";

function removeCommonLeadingWhitespace(input: string): string {
  const lines = input.split("\n");
  // Remove leading and trailiing empty lines
  while (lines.length && !lines[0].trim()) lines.shift();
  while (lines.length && !lines[lines.length - 1].trim()) lines.pop();
  // Find the minimum number of leading spaces in non-empty lines
  const minSpaces = Math.min(...lines.filter((line) => line.trim()).map((line) => line.match(/^\s*/)![0].length));
  return minSpaces > 0 ? lines.map((line) => line.slice(minSpaces)).join("\n") : input.trim();
}

type RawChangelogItem = {
  date: string;
  title: string;
  description: string;
  image?: string;
};

export type ChangelogItem = {
  index: string;
  date: DateTime<true>;
  title: string;
  description: string;
  image?: string;
};

export function parseChangelogItems(items: RawChangelogItem[]): ChangelogItem[] {
  const dateIndexMap = new Map<string, number>();
  const sortedItems = [...items].reverse().sort((a, b) => a.date.localeCompare(b.date));
  const parsedItems = sortedItems
    .map((item) => {
      const date = DateTime.fromISO(item.date);
      if (!date.isValid) return null;
      const dateString = date.toISODate({ format: "basic" });
      const currentIndex = (dateIndexMap.get(dateString) || 0) + 1;
      dateIndexMap.set(dateString, currentIndex);

      return {
        ...item,
        description: removeCommonLeadingWhitespace(item.description),
        date,
        index: `${dateString}-${currentIndex}`,
        image: item.image
          ? !item.image.startsWith("https://")
            ? "https://assets.apitally.io/changelog/" + item.image
            : item.image
          : undefined,
      };
    })
    .filter((item) => item !== null);
  return parsedItems.reverse();
}

export function getNewChangelogItemCount(): number {
  const lastSeen = localStorage.getItem("changelogLastSeen");
  return lastSeen ? changelog.filter((item) => item.index > lastSeen).length : 1;
}
