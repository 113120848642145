import { FieldInputProps, useFormikContext } from "formik";
import ReactSelect, { GroupBase, OnChangeValue, StylesConfig, ThemeConfig } from "react-select";
import CreatableReactSelect, { CreatableProps } from "react-select/creatable";

export type Option<T = number> = { value: T; label: string };
export declare type OptionGroup<T = number> = GroupBase<Option<T>>;

export function getSelectStyles<OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>() {
  const styles: StylesConfig<OptionType, IsMulti, Group> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      "borderColor": state.isFocused ? "#85cbcb" : "var(--bs-border-color)",
      "boxShadow": state.isFocused ? "0 0 0 0.25rem rgba(11, 151, 151, 0.25)" : "none",
      "&:hover": {
        borderColor: undefined,
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      "backgroundColor": state.isSelected ? "var(--bs-primary)" : state.isFocused ? "#f8f9fa" : undefined,
      "&:hover": {
        backgroundColor: !state.isSelected ? "#f8f9fa" : undefined,
      },
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "var(--bs-gray-500)",
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      "color": "var(--bs-gray-500)",
      "&:hover": {
        color: "var(--bs-secondary)",
      },
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      "color": "var(--bs-gray-500)",
      "&:hover": {
        color: "var(--bs-secondary)",
      },
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "var(--bs-border-color)",
    }),
  };
  const theme: ThemeConfig = (theme) => ({
    ...theme,
    borderRadius: 6,
    colors: {
      ...theme.colors,
      primary: "var(--bs-primary)",
      primary75: "rgba(11, 151, 151, 0.75)",
      primary50: "rgba(11, 151, 151, 0.5)",
      primary25: "rgba(11, 151, 151, 0.25)",
      danger: "var(--bs-danger)",
      dangerLight: "rgba(196, 106, 106, 0.25)",
    },
  });
  return { styles, theme };
}

declare type BaseProps<T> = CreatableProps<Option<T>, false, GroupBase<Option<T>>>;
export type SelectProps<T> = { onChangeExtra?: () => void } & Omit<
  BaseProps<T>,
  "value" | "onChange" | "onBlur" | "isMulti"
> &
  FieldInputProps<T>;

function Select<T = number>({
  options,
  value,
  onChange, // eslint-disable-line @typescript-eslint/no-unused-vars
  onChangeExtra,
  onCreateOption,
  ...props
}: SelectProps<T>) {
  const { setFieldValue } = useFormikContext();

  const flattenedOptions = options?.flatMap((o) => {
    const isNotGrouped = "value" in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });
  const selectedOption = flattenedOptions?.filter((option) => value === option.value);
  const onChangeSetFieldValue = (selectedOption: OnChangeValue<Option<T>, false>) => {
    setFieldValue(props.name, selectedOption !== null ? selectedOption.value : undefined);
    if (onChangeExtra) {
      onChangeExtra();
    }
  };
  const { styles, theme } = getSelectStyles<Option<T>, false, GroupBase<Option<T>>>();

  if (onCreateOption) {
    return (
      <CreatableReactSelect
        options={options}
        value={selectedOption}
        onChange={onChangeSetFieldValue}
        onCreateOption={onCreateOption}
        styles={styles}
        theme={theme}
        {...props}
      />
    );
  } else {
    return (
      <ReactSelect
        options={options}
        value={selectedOption}
        onChange={onChangeSetFieldValue}
        styles={styles}
        theme={theme}
        {...props}
      />
    );
  }
}

export default Select;
