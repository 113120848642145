import { useAuth0 } from "@auth0/auth0-react";
import { faGithub, faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  faCircleQuestion,
  faCircleUp,
  faClock,
  faCreditCard,
  faHourglassHalf,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRotateRight,
  faCog,
  faDoorOpen,
  faEye,
  faKey,
  faPenToSquare,
  faPlus,
  faSpinner,
  faTrashCan,
  faTriangleExclamation,
  faUser,
  faUserMinus,
  faUserPen,
  faWarning,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { TeamInviteItem, TeamUserItem, UpdateTeamUserRequestBody } from "./backend";
import InviteForm from "./components/InviteForm";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import PageSubHeader from "./components/PageSubHeader";
import PlanLimitsBars from "./components/PlanLimitsBars";
import TableCard from "./components/TableCard";
import TableCellDropdown from "./components/TableCellDropdown";
import TeamDropdown from "./components/TeamDropdown";
import Tooltip from "./components/Tooltip";
import UnverifiedEmailAlert from "./components/UnverifiedEmailAlert";
import UpgradePlanAlert from "./components/UpgradePlanAlert";
import UserImage from "./components/UserImage";
import { useGlobal } from "./contexts/GlobalContext";
import AccountDeleteModal from "./modals/AccountDeleteModal";
import PlansModal from "./modals/PlansModal";
import ProfileFormModal from "./modals/ProfileFormModal";
import TeamDeleteModal from "./modals/TeamDeleteModal";
import TeamFormModal from "./modals/TeamFormModal";
import TeamIntegrationsModal from "./modals/TeamIntegrationsModal";
import TeamInviteDetailsModal from "./modals/TeamInviteDetailsModal";
import TeamLeaveModal from "./modals/TeamLeaveModal";
import TeamUserDetailsModal from "./modals/TeamUserDetailsModal";
import "./Settings.scss";

function Settings() {
  const { user, loginWithRedirect } = useAuth0();
  const { activeTeam, isTeamAdmin, isTeamOwner } = useGlobal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPlansModal, setShowPlansModal] = useState(searchParams.get("upgradePlan") !== null);
  const [showProfileFormModal, setShowProfileFormModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [teamFormModalTeamId, setTeamFormModalTeamId] = useState<number>();
  const [showTeamIntegrationsModal, setShowTeamIntegrationsModal] = useState(
    searchParams.get("configureIntegrations") !== null,
  );
  const [showTeamLeaveModal, setShowTeamLeaveModal] = useState(false);
  const [showTeamDeleteModal, setShowTeamDeleteModal] = useState(false);
  const loginMethod = user?.sub?.split("|")[0];
  const canLeaveTeam = activeTeam && (activeTeam.users_count > 1 || activeTeam.demo);
  const canDeleteTeam =
    isTeamOwner && activeTeam?.users_count === 1 && activeTeam?.apps_count === 0 && !activeTeam.demo;

  useEffect(() => {
    document.title = "Settings - Apitally";
    (window as any).createLemonSqueezy();
  }, []);

  useEffect(() => {
    if (!showPlansModal && searchParams.has("upgradePlan")) {
      setSearchParams((searchParams) => {
        searchParams.delete("upgradePlan");
        return searchParams;
      });
    }
  }, [showPlansModal]);

  useEffect(() => {
    if (!showTeamIntegrationsModal && searchParams.has("configureIntegrations")) {
      setSearchParams((searchParams) => {
        searchParams.delete("configureIntegrations");
        return searchParams;
      });
    }
  }, [showTeamIntegrationsModal]);

  const changePassword = async () => {
    // Ask user to log in again before redirecting to change password
    await loginWithRedirect({
      appState: { changePassword: true },
      authorizationParams: { prompt: "login", max_age: 60 },
    });
  };

  const pageHeaderButtons = [
    <Button key="new-team" variant="primary" disabled={!user?.email_verified} onClick={() => setTeamFormModalTeamId(0)}>
      <FontAwesomeIcon icon={faPlus} className="me-2" />
      New team
    </Button>,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={!user?.email_verified} onClick={() => setTeamFormModalTeamId(0)}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
        New team
      </Dropdown.Item>
      {(isTeamAdmin || canLeaveTeam || canDeleteTeam) && (
        <>
          <Dropdown.Divider />
          {isTeamAdmin && (
            <Dropdown.Item as="button" onClick={() => setShowTeamIntegrationsModal(true)}>
              <FontAwesomeIcon icon={faCog} fixedWidth />
              Configure integrations
            </Dropdown.Item>
          )}
          {isTeamAdmin && (
            <Dropdown.Item as="button" onClick={() => setTeamFormModalTeamId(activeTeam?.id)}>
              <FontAwesomeIcon icon={faPenToSquare} fixedWidth />
              Rename team
            </Dropdown.Item>
          )}
          {canLeaveTeam && (
            <Dropdown.Item as="button" className="text-danger" onClick={() => setShowTeamLeaveModal(true)}>
              <FontAwesomeIcon icon={faDoorOpen} fixedWidth />
              Leave team
            </Dropdown.Item>
          )}
          {canDeleteTeam && (
            <Dropdown.Item as="button" className="text-danger" onClick={() => setShowTeamDeleteModal(true)}>
              <FontAwesomeIcon icon={faTrashCan} fixedWidth />
              Delete team
            </Dropdown.Item>
          )}
        </>
      )}
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Settings">
        <PlansModal show={showPlansModal} setShow={setShowPlansModal} />
        <ProfileFormModal
          show={showProfileFormModal}
          setShow={setShowProfileFormModal}
          setShowDeleteAccountModal={setShowDeleteAccountModal}
        />
        <AccountDeleteModal show={showDeleteAccountModal} setShow={setShowDeleteAccountModal} />
        <TeamFormModal teamId={teamFormModalTeamId} setTeamId={setTeamFormModalTeamId} />
        <TeamIntegrationsModal show={showTeamIntegrationsModal} setShow={setShowTeamIntegrationsModal} />
        <TeamLeaveModal show={showTeamLeaveModal} setShow={setShowTeamLeaveModal} />
        <TeamDeleteModal show={showTeamDeleteModal} setShow={setShowTeamDeleteModal} />
        <PageHeader buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          <>Settings</>
          <TeamDropdown />
        </PageHeader>
        {user && !user.email_verified && <UnverifiedEmailAlert />}
        <Card className="profile bt mb-6">
          <Card.Body>
            <Container fluid>
              <Row>
                <Col xs={12} lg={6} className="p-0">
                  <Stack direction="horizontal" gap={4}>
                    <div className="d-none d-sm-block">
                      <UserImage
                        user={user}
                        className="ps-2 py-1"
                        width="100%"
                        style={{ maxWidth: "90px", maxHeight: "90px" }}
                      />
                    </div>
                    <div>
                      <div className="fw-bold">{user?.name}</div>
                      <div>
                        {user?.email}
                        {user?.email_verified ? <span className="ms-2 small text-muted">(verified)</span> : ""}
                        {user && !user.email_verified ? (
                          <span className="ms-2 small text-danger">(not verified)</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <Stack direction="horizontal" gap={4} className="actions mt-4">
                        <Button variant="link" onClick={() => setShowProfileFormModal(true)}>
                          <FontAwesomeIcon icon={faUserPen} className="me-icon" />
                          Edit profile
                        </Button>
                        {loginMethod === "auth0" && (
                          <Button variant="link" onClick={() => changePassword()}>
                            <FontAwesomeIcon icon={faKey} className="me-icon" />
                            Change password
                          </Button>
                        )}
                        {loginMethod === "github" && (
                          <span className="small text-muted">
                            <FontAwesomeIcon icon={faGithub} className="me-icon" />
                            Logged in via GitHub
                          </span>
                        )}
                        {loginMethod === "google-oauth2" && (
                          <span className="small text-muted">
                            <FontAwesomeIcon icon={faGoogle} className="me-icon" />
                            Logged in via Google
                          </span>
                        )}
                      </Stack>
                    </div>
                  </Stack>
                </Col>
                <Col lg={6} className="d-none d-lg-block border-start ps-6">
                  <div>
                    <div className="fw-bold">{activeTeam?.name}</div>
                    <div>
                      {activeTeam?.apps_count} app{activeTeam?.apps_count !== 1 ? "s" : ""}
                      <span className="mx-1" style={{ opacity: 0.6 }}>
                        /
                      </span>
                      {activeTeam?.users_count} user{activeTeam?.users_count !== 1 ? "s" : ""}
                    </div>
                    {(isTeamAdmin || canLeaveTeam || canDeleteTeam) && (
                      <Stack direction="horizontal" gap={4} className="actions mt-4">
                        {isTeamAdmin && (
                          <Button variant="link" onClick={() => setShowTeamIntegrationsModal(true)}>
                            <FontAwesomeIcon icon={faCog} className="me-icon" />
                            Configure integrations
                          </Button>
                        )}
                        {isTeamAdmin && (
                          <Button variant="link" onClick={() => setTeamFormModalTeamId(activeTeam?.id)}>
                            <FontAwesomeIcon icon={faPenToSquare} className="me-icon" />
                            Rename team
                          </Button>
                        )}
                        {canLeaveTeam && (
                          <Button variant="link" className="text-danger" onClick={() => setShowTeamLeaveModal(true)}>
                            <FontAwesomeIcon icon={faDoorOpen} className="me-icon" />
                            Leave team
                          </Button>
                        )}
                        {canDeleteTeam && (
                          <Button variant="link" className="text-danger" onClick={() => setShowTeamDeleteModal(true)}>
                            <FontAwesomeIcon icon={faTrashCan} className="me-icon" />
                            Delete team
                          </Button>
                        )}
                      </Stack>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <PageSubHeader>
          <>Team</>
          <>Subscription</>
        </PageSubHeader>
        <TeamSubscription setShowPlansModal={setShowPlansModal} />
        <PageSubHeader>
          <>Team</>
          <>Users</>
        </PageSubHeader>
        <TeamUsers setShowPlansModal={setShowPlansModal} />
      </div>
    </MainLayout>
  );
}

type TeamSubscriptionProps = {
  setShowPlansModal: (show: boolean) => void;
};

function TeamSubscription({ setShowPlansModal }: TeamSubscriptionProps) {
  const { user: authenticatedUser } = useAuth0();
  const { activeTeam, backendClient, userId, isTeamOwner, teams, teamPlan, refreshTeamPlan } = useGlobal();
  const [customerPortalLoading, setCustomerPortalLoading] = useState(false);
  const [updatePaymentMethodLoading, setUpdatePaymentMethodLoading] = useState(false);

  const queryParams = { teamId: activeTeam?.id || 0 };
  const query = useQuery({
    queryKey: ["team", queryParams],
    queryFn: () => backendClient!.teams.getTeam(queryParams),
    enabled: !!backendClient && !!activeTeam,
  });
  const isBillingUser = query.data?.billing_user?.id === userId;

  useEffect(() => {
    if (teams && query.isSuccess) {
      query.refetch();
    }
  }, [teams]);

  useEffect(() => {
    refreshTeamPlan();
  }, [query.data]);

  const manageSubscription = async () => {
    if (activeTeam && backendClient) {
      setCustomerPortalLoading(true);
      const promise = backendClient.lemonSqueezy.getSubscriptionUrls({ teamId: activeTeam.id });
      toast.promise(promise, {
        pending: "Creating customer portal session...",
        success: "Redirecting to customer portal...",
        error: "Failed to get customer portal session.",
      });
      try {
        const urls = await promise;
        window.location.href = urls.customer_portal;
      } catch (e) {
        setCustomerPortalLoading(false);
      }
    }
  };

  const updatePaymentMethod = async () => {
    if (activeTeam && backendClient) {
      setUpdatePaymentMethodLoading(true);
      const promise = backendClient.lemonSqueezy.getSubscriptionUrls({ teamId: activeTeam.id });
      toast.promise(promise, {
        pending: "Creating customer portal session...",
        success: "Opening customer portal...",
        error: "Failed to get customer portal session.",
      });
      try {
        const urls = await promise;
        // @ts-expect-error LemonSqueezy is not typed
        LemonSqueezy.Url.Open(urls.update_payment_method);
      } finally {
        setUpdatePaymentMethodLoading(false);
      }
    }
  };

  return (
    <div className="TeamSubscription">
      <Card className="bt mb-6">
        <Card.Body>
          <Container fluid>
            <Row>
              <Col xs={12} md={4} className="ps-0">
                <div className="text-muted small">Subscription</div>
                <div className="fw-bold">{teamPlan?.name}</div>
                {isTeamOwner && (
                  <Stack direction="horizontal" gap={4} className="actions mt-4">
                    {teamPlan?.upgradable && (
                      <Button
                        variant="link"
                        disabled={!authenticatedUser?.email_verified}
                        onClick={() => setShowPlansModal(true)}
                      >
                        <FontAwesomeIcon icon={faCircleUp} className="me-icon" />
                        Upgrade plan
                      </Button>
                    )}
                    {teamPlan?.subscription?.lmsqueezy_subscription_id && (
                      <Tooltip
                        condition={!isBillingUser}
                        tooltip={`Only ${query.data?.billing_user?.name} can manage the subscription and billing settings`}
                        placement="bottom"
                      >
                        <Button
                          variant="link"
                          disabled={!isBillingUser || customerPortalLoading}
                          onClick={() => manageSubscription()}
                        >
                          {customerPortalLoading ? (
                            <FontAwesomeIcon icon={faSpinner} spin className="me-icon" />
                          ) : (
                            <FontAwesomeIcon icon={faCog} className="me-icon" />
                          )}
                          Manage subscription
                        </Button>
                      </Tooltip>
                    )}
                  </Stack>
                )}
              </Col>
              <Col xs={12} md={4} className="border-top border-start">
                <div className="text-muted small">Payment status</div>
                <div className="fw-bold">
                  {teamPlan?.subscription ? capitalize(teamPlan.subscription.status.replaceAll("_", " ")) : "n/a"}
                  {teamPlan?.subscription && ["past_due", "unpaid"].includes(teamPlan?.subscription.status) && (
                    <Tooltip
                      tooltip={
                        teamPlan.subscription.status == "past_due"
                          ? "Renewal payment has failed. We will retry 4 times over the course of 2 weeks."
                          : "4 payment attempts were unsuccessful. Please update your payment method to avoid interruption."
                      }
                      placement="bottom"
                    >
                      <span className="text-danger ms-icon">
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      </span>
                    </Tooltip>
                  )}
                </div>
                {teamPlan?.subscription?.status === "active" && teamPlan?.subscription?.renews_at && (
                  <div className="mt-4 small">
                    Next payment due{" "}
                    {DateTime.fromISO(teamPlan?.subscription?.renews_at).toRelativeCalendar({ unit: "days" })}
                  </div>
                )}
                {teamPlan?.subscription?.status === "cancelled" && teamPlan?.subscription?.ends_at && (
                  <div className="mt-4 small">
                    Subscription expires{" "}
                    {DateTime.fromISO(teamPlan?.subscription?.ends_at).toRelativeCalendar({ unit: "days" })}
                  </div>
                )}
                {teamPlan?.subscription && ["past_due", "unpaid"].includes(teamPlan?.subscription?.status) && (
                  <Stack direction="horizontal" gap={4} className="actions mt-4">
                    <Tooltip
                      condition={!isBillingUser}
                      tooltip={`Only ${query.data?.billing_user?.name} can update the payment method`}
                      placement="bottom"
                    >
                      <Button
                        variant="link"
                        disabled={!isBillingUser || updatePaymentMethodLoading}
                        onClick={() => updatePaymentMethod()}
                      >
                        {updatePaymentMethodLoading ? (
                          <FontAwesomeIcon icon={faSpinner} spin className="me-icon" />
                        ) : (
                          <FontAwesomeIcon icon={faCreditCard} className="me-icon" />
                        )}
                        Update payment method
                      </Button>
                    </Tooltip>
                  </Stack>
                )}
              </Col>
              <Col xs={12} md={4} className="border-top border-start">
                <PlanLimitsBars className="p-0" />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </div>
  );
}

type RoleDropdownProps = {
  userOrInvite: TeamUserItem | TeamInviteItem;
};

function RoleDropdown({ userOrInvite }: RoleDropdownProps) {
  const { activeTeam, backendClient, isTeamOwner } = useGlobal();
  const [currentRole, setCurrentRole] = useState<string>(userOrInvite.role);

  const getRoleDropdownItems = (currentRole: string) => {
    const roleDropdownItems = ["member", "admin", "owner"].map((role) =>
      isTeamOwner || role !== "owner" ? (
        <Dropdown.Item key={role} eventKey={role} disabled={role === currentRole}>
          {capitalize(role)}
        </Dropdown.Item>
      ) : undefined,
    );
    return roleDropdownItems.filter((item) => item !== undefined);
  };

  const changeRole = async (role: string) => {
    if (backendClient && activeTeam) {
      const promise =
        "name" in userOrInvite
          ? backendClient.teams.updateTeamUser({
              teamId: activeTeam.id,
              userId: userOrInvite.id,
              requestBody: { role: role as UpdateTeamUserRequestBody["role"] },
            })
          : backendClient.teams.updateInvite({
              teamId: activeTeam.id,
              inviteId: userOrInvite.id,
              requestBody: { role: role as UpdateTeamUserRequestBody["role"] },
            });
      toast.promise(promise, {
        error: "Failed to update role.",
      });
      await promise;
      setCurrentRole(role);
    }
  };

  return (
    <Dropdown onSelect={(key) => key !== null && changeRole(key)}>
      <Dropdown.Toggle variant="link">{capitalize(currentRole)}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>Change role to:</Dropdown.Header>
        {getRoleDropdownItems(currentRole)}
      </Dropdown.Menu>
    </Dropdown>
  );
}

type TeamUsersProps = {
  setShowPlansModal: (show: boolean) => void;
};

function TeamUsers({ setShowPlansModal }: TeamUsersProps) {
  const { user: authenticatedUser } = useAuth0();
  const { backendClient, activeTeam, isTeamAdmin, teamPlan } = useGlobal();
  const [teamUserDetailsModalUser, setTeamUserDetailsModalUser] = useState<TeamUserItem>();
  const [teamInviteDetailsModalInvite, setTeamInviteDetailsModalInvite] = useState<TeamInviteItem>();

  const queryParams = { teamId: activeTeam?.id || 0 };
  const query = useQuery({
    queryKey: ["team", queryParams],
    queryFn: () => backendClient!.teams.getTeam(queryParams),
    enabled: !!backendClient && !!activeTeam,
  });

  const removeUser = async (user: TeamUserItem) => {
    if (backendClient && activeTeam) {
      const promise = backendClient.teams.removeUserFromTeam({ teamId: activeTeam.id, userId: user.id });
      toast.promise(promise, {
        success: "User removed from team!",
        error: "Failed to remove user from team.",
      });
      await promise;
      query.refetch();
    }
  };

  const resendInvite = async (invite: TeamInviteItem) => {
    if (backendClient && activeTeam) {
      const promise = backendClient.teams.resendInvite({ teamId: activeTeam.id, inviteId: invite.id });
      toast.promise(promise, {
        pending: "Resending invite...",
        success: "Invite sent!",
        error: "Failed to send invite.",
      });
      await promise;
    }
  };

  const cancelInvite = async (invite: TeamInviteItem) => {
    if (backendClient && activeTeam) {
      const promise = backendClient.teams.cancelInvite({ teamId: activeTeam.id, inviteId: invite.id });
      toast.promise(promise, {
        success: "Invite cancelled!",
        error: "Failed to cancel invite.",
      });
      await promise;
      query.refetch();
    }
  };

  const tooManyUsersAlert = query.data?.users.some((user) => user.suspended) ? (
    <UpgradePlanAlert variant="danger" upgradeButtonOnClick={() => setShowPlansModal(true)}>
      Your team has more users than allowed in the {teamPlan?.name}. Please remove users or upgrade your plan.
    </UpgradePlanAlert>
  ) : undefined;

  const inviteRows = query.data?.invites.map((invite) => {
    const allowModify = isTeamAdmin && !(invite.role === "owner" && activeTeam?.role !== "owner");

    return (
      <tr key={`invite/${invite.id}`}>
        <td style={{ width: 40 }}>
          <FontAwesomeIcon icon={faHourglassHalf} className="ms-2 text-very-muted" />
        </td>
        <td className="fst-italic d-none d-sm-table-cell">Pending invite</td>
        <td className="fst-italic d-sm-none">{invite.email}</td>
        <td className="d-none d-sm-table-cell">{invite.email}</td>
        <td className="d-none d-md-table-cell">
          <span>
            <FontAwesomeIcon icon={faClock} className="me-2 text-secondary" />
            Never
          </span>
        </td>
        <td>
          {allowModify && <RoleDropdown userOrInvite={invite} />}
          {!allowModify && capitalize(invite.role)}
        </td>
        <TableCellDropdown>
          <Dropdown.Item as="button" onClick={() => setTeamInviteDetailsModalInvite(invite)}>
            <FontAwesomeIcon icon={faEye} fixedWidth />
            Invite details
          </Dropdown.Item>
          {isTeamAdmin && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={() => resendInvite(invite)}>
                <FontAwesomeIcon icon={faArrowRotateRight} fixedWidth />
                Resend invite
              </Dropdown.Item>
              {allowModify && (
                <Dropdown.Item as="button" className="text-danger" onClick={() => cancelInvite(invite)}>
                  <FontAwesomeIcon icon={faXmark} fixedWidth />
                  Cancel invite
                </Dropdown.Item>
              )}
            </>
          )}
        </TableCellDropdown>
      </tr>
    );
  });

  const userRows = query.data?.users.map((user) => {
    const lastLoginDate = DateTime.fromISO(user.last_login_at);
    const isBillingUser = query.data?.billing_user?.id === user.id;
    const allowModify =
      isTeamAdmin &&
      !(user.role === "owner" && activeTeam?.role !== "owner") &&
      user.auth0_user_id !== authenticatedUser?.sub &&
      !isBillingUser;
    const differentEmailInfo =
      user.invite_email && user.email !== user.invite_email ? (
        <Tooltip placement="bottom" tooltip={`Invited as ${user.invite_email}`}>
          <span className="ms-2 text-secondary small">
            <FontAwesomeIcon icon={faCircleQuestion} />
          </span>
        </Tooltip>
      ) : undefined;
    const unverifiedEmailWarning = user.email_verified ? undefined : (
      <Tooltip placement="bottom" tooltip="Email address not verified">
        <span className="ms-2 text-danger small">
          <FontAwesomeIcon icon={faWarning} />
        </span>
      </Tooltip>
    );
    const billingUserIcon = (
      <Tooltip tooltip="Billing contact for this team's subscription">
        <span className="ms-2 text-secondary small">
          <FontAwesomeIcon icon={faCreditCard} />
        </span>
      </Tooltip>
    );

    return (
      <tr key={`user/${user.id}`}>
        <td style={{ width: 40 }}>
          <FontAwesomeIcon
            icon={faUser}
            style={{ filter: user.suspended ? "saturate(0%) brightness(150%)" : undefined }}
            className="ms-2 text-very-muted"
          />
        </td>
        <td className={user.suspended ? "text-muted text-decoration-line-through" : undefined}>{user.name}</td>
        <td className="d-none d-sm-table-cell">
          {user.email}
          {differentEmailInfo}
          {unverifiedEmailWarning}
        </td>
        <td className="d-none d-md-table-cell">
          <span title={lastLoginDate.toLocaleString(DateTime.DATETIME_FULL)} className="text-nowrap">
            <FontAwesomeIcon icon={faClock} className="me-2 text-secondary" />
            {lastLoginDate.toRelative()}
          </span>
        </td>
        <td>
          {allowModify && <RoleDropdown userOrInvite={user} />}
          {!allowModify && (
            <>
              {capitalize(user.role)}
              {isBillingUser ? billingUserIcon : undefined}
            </>
          )}
        </td>
        <TableCellDropdown>
          <Dropdown.Item as="button" onClick={() => setTeamUserDetailsModalUser(user)}>
            <FontAwesomeIcon icon={faEye} fixedWidth className="text-secondary" />
            User details
          </Dropdown.Item>
          {allowModify && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item as="button" className="text-danger" onClick={() => removeUser(user)}>
                <FontAwesomeIcon icon={faUserMinus} fixedWidth />
                Remove from team
              </Dropdown.Item>
            </>
          )}
        </TableCellDropdown>
      </tr>
    );
  });

  return (
    <div className="TeamUsers">
      <TeamUserDetailsModal user={teamUserDetailsModalUser} setUser={setTeamUserDetailsModalUser} />
      <TeamInviteDetailsModal invite={teamInviteDetailsModalInvite} setInvite={setTeamInviteDetailsModalInvite} />
      {tooManyUsersAlert}
      {isTeamAdmin && (
        <div className="mb-4">
          <InviteForm />
        </div>
      )}
      <TableCard className="align-middle">
        <thead>
          <tr>
            <th style={{ width: 40 }}></th>
            <th>User name</th>
            <th className="d-none d-sm-table-cell">Email address</th>
            <th className="d-none d-md-table-cell">Last login</th>
            <th>Role</th>
            <th style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>
          {inviteRows}
          {userRows}
          {!inviteRows && !userRows && (
            <Placeholder as="tr" animation="glow">
              <td style={{ width: 40 }}></td>
              <td>
                <Placeholder xs={6} />
              </td>
              <td className="d-none d-sm-table-cell">
                <Placeholder xs={6} />
              </td>
              <td className="d-none d-md-table-cell">
                <Placeholder xs={6} />
              </td>
              <td>
                <Placeholder xs={4} />
              </td>
              <td style={{ width: 40 }}></td>
            </Placeholder>
          )}
        </tbody>
      </TableCard>
    </div>
  );
}

export default Settings;
