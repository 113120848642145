import { useQuery } from "@tanstack/react-query";
import {
  ActiveElement,
  BarController,
  BarElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { memo } from "react";
import { Bar } from "react-chartjs-2";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { EndpointError } from "../types/Endpoint";
import { getColor } from "../utils/colors";

ChartJS.register(BarController, BarElement, CategoryScale, Title, Tooltip);

type ErrorsByConsumerBarChartProps = {
  endpointError: EndpointError;
};

function ErrorsByConsumerBarChart({ endpointError }: ErrorsByConsumerBarChartProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, setConsumerId, consumerGroupId } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerGroupId,
    method: endpointError.method,
    path: endpointError.path,
    statusCode: endpointError.status_code,
    period,
  };
  const query = useQuery({
    queryKey: ["errorsByConsumerChart", queryParams],
    queryFn: () => backendClient!.errors.getErrorsByConsumerChart(queryParams),
    enabled: !!backendClient && !!app,
  });

  let chart;
  let chartHeight = 120;
  if (query.isSuccess) {
    const dangerColor = getColor("danger");
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      font: {
        family: "DM Sans",
      },
      indexAxis: "y" as const,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: true,
            color: "#faf9fb",
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              family: "DM Sans",
            },
            color: "#adb5bd",
          },
          display: true,
          beginAtZero: true,
          precision: 0,
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              family: "DM Sans",
              weight: "bold" as const,
            },
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        tooltip: {
          mode: "index" as const,
          callbacks: {
            label: function (context: TooltipItem<"bar">) {
              const value = context.parsed.x.toLocaleString();
              return ` ${value}`;
            },
          },
          titleFont: {
            family: "DM Sans",
            size: 12,
            weight: "bold" as const,
          },
          bodyFont: {
            family: "DM Sans",
            size: 12,
            weight: "normal" as const,
          },
        },
        legend: {
          display: false,
        },
      },
      onHover: (event: ChartEvent, elements: ActiveElement[]) => {
        if (event.native?.target && event.native.target instanceof HTMLElement) {
          event.native.target.style.cursor = elements.length > 0 ? "pointer" : "default";
        }
      },
      onClick: (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
        if (event.native) {
          const res = chart.getElementsAtEventForMode(event.native, "y", { intersect: true }, true);
          if (res.length > 0) {
            const index = res[0].index;
            const consumerId = query.data.consumer_ids[index];
            setConsumerId(consumerId);
          }
        }
      },
    };
    const chartData = {
      datasets: [
        {
          data: query.data.consumer_names.slice(0, 10).map((consumer_name, index) => ({
            y: consumer_name,
            x: query.data.request_counts[index],
          })),
          backgroundColor: endpointError.status_code >= 500 ? dangerColor : "#dca6a6",
          hoverBackgroundColor: endpointError.status_code >= 500 ? dangerColor : "#dca6a6",
        },
      ],
    };
    if (query.data.consumer_ids.length === 0) {
      return <div className="text-center py-2">No occurrences for identified consumers in the selected period.</div>;
    } else {
      chartHeight = 40 + Math.min(query.data.consumer_ids.length, 10) * 40;
      chart = <Bar data={chartData} options={chartOptions} />;
    }
  }

  return <ChartContainer height={chartHeight}>{chart}</ChartContainer>;
}

export default memo(ErrorsByConsumerBarChart);
