import {
  BarController,
  BarElement,
  Chart as ChartJS,
  LinearScale,
  ScriptableTooltipContext,
  TimeSeriesScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import { DateTime } from "luxon";
import { rgba } from "polished";
import { memo } from "react";
import { Bar } from "react-chartjs-2";

import { UptimeChartsResponseItem } from "../backend";
import { getColor } from "../utils/colors";
import "./UptimeBarChart.css";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement, Tooltip);

function externalTooltip(context: ScriptableTooltipContext<"bar">) {
  const tooltipModel = context.tooltip;
  let tooltipElement = document.getElementById("chartjs-tooltip");

  if (!tooltipElement) {
    tooltipElement = document.createElement("div");
    tooltipElement.id = "chartjs-tooltip";
    tooltipElement.classList.add("chartjs-tooltip");
    tooltipElement.innerHTML = '<div class="tooltip-caret"></div><div class="tooltip-content"></div>';
    document.body.appendChild(tooltipElement);
  }

  // Hide tooltip
  if (tooltipModel.opacity === 0) {
    tooltipElement.style.opacity = "0";
    return;
  }

  // Set tooltip content
  const dataIndex = context.tooltip.dataPoints[0].dataIndex;
  const data = (context.chart.data.datasets[0].data[dataIndex] as any).data as UptimeChartsResponseItem;

  const timeWindowSize = DateTime.fromISO(context.chart.data.labels![1] as string).diff(
    DateTime.fromISO(context.chart.data.labels![0] as string),
  );
  const timestamp = DateTime.fromISO(tooltipModel.title[0]);
  const timestampEnd = timestamp.plus(timeWindowSize);
  const useAmericanDateFormat = navigator.language === "en-US";
  const hyphen = " – "; // punctuation space and en dash
  const dateTimeFormat = (useAmericanDateFormat ? "EEEE, MMMM d" : "EEEE, d MMMM") + ", HH:mm";
  const formattedTimestamp = timestamp.toFormat(dateTimeFormat) + hyphen + timestampEnd.toFormat("HH:mm");
  const titleHtml = `<div class="fw-bold">${formattedTimestamp}</div>`;

  const uptimePercentage = data ? data.uptime.success_count / data.uptime.total_count : NaN;
  const uptimePercentageString =
    !isNaN(uptimePercentage) && data.uptime.status !== null
      ? (data.uptime.success_count / data.uptime.total_count).toLocaleString(undefined, {
          style: "percent",
          maximumFractionDigits: 1,
        })
      : undefined;
  const uptimeHtml =
    uptimePercentage < 1 && uptimePercentageString ? `<div class="mt-1">${uptimePercentageString} uptime</div>` : "";

  const healthChecksFailed = data.health_checks.total_count - data.health_checks.success_count;
  const healthChecksHtml =
    healthChecksFailed > 0
      ? `<div class="mt-1">${healthChecksFailed} of ${data.health_checks.total_count} health checks failed:</div><div>${data.health_checks.errors?.join("<br>")}</div>`
      : "";

  const noProblemsHtml =
    uptimePercentage === 1 && healthChecksFailed === 0 ? `<div class="mt-1">No problems detected</div>` : "";

  const contentElement = tooltipElement.querySelector(".tooltip-content");
  if (contentElement) {
    contentElement.innerHTML = titleHtml + uptimeHtml + healthChecksHtml + noProblemsHtml;
  }

  // Position and show tooltip
  const position = context.chart.canvas.getBoundingClientRect();
  tooltipElement.style.opacity = "0.75";
  tooltipElement.style.left =
    position.left + window.scrollX + tooltipModel.caretX - tooltipElement.clientWidth / 2 + "px";
  tooltipElement.style.top = position.top + window.scrollY + tooltipModel.caretY + 46 + "px";
}

type UptimeBarChartProps = {
  timeWindows: string[];
  data?: UptimeChartsResponseItem[];
};

function UptimeBarChart({ timeWindows, data }: UptimeBarChartProps) {
  if (!data) {
    data = timeWindows.map(() => ({
      uptime: { status: null, success_count: 0, total_count: 0 },
      health_checks: { status: null, success_count: 0, total_count: 0 },
    }));
  }
  const statusTimeSeries = data.map((item) => {
    if (item.uptime.status === null && item.health_checks.status === null) {
      return "unknown";
    } else if (
      (item.uptime.status && item.health_checks.status) ||
      (item.uptime.status && item.health_checks.status === null) ||
      (item.uptime.status === null && item.health_checks.status)
    ) {
      return "up";
    }
    return "down";
  });

  const primaryColor = getColor("primary");
  const dangerColor = getColor("danger");
  const secondaryColor = getColor("secondary");
  const colorMap = new Map([
    ["up", rgba(primaryColor, 0.6)],
    ["down", dangerColor],
    ["unknown", rgba(secondaryColor, 0.2)],
  ]);
  const hoverColorMap = new Map([
    ["up", primaryColor],
    ["down", dangerColor],
    ["unknown", secondaryColor],
  ]);
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: externalTooltip,
      },
    },
  };
  const chartData = {
    labels: timeWindows,
    datasets: [
      {
        data: timeWindows.map((x, i) => ({ x: x, y: 1, data: data![i] })),
        backgroundColor: statusTimeSeries.map((value) => colorMap.get(value)),
        hoverBackgroundColor: statusTimeSeries.map((value) => hoverColorMap.get(value)),
        borderWidth: { left: 1, right: 1, top: 0, bottom: 0 },
        borderColor: "#ffffff00",
        borderRadius: 15,
        borderSkipped: false,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
    ],
  };
  return (
    <div className="UptimeBarChart">
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
}

export default memo(UptimeBarChart);
