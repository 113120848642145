import Spinner from "react-bootstrap/Spinner";

type ChartContainerProps = {
  height?: number;
  loading?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

function ChartContainer({ height = 220, loading = false, style, children }: ChartContainerProps) {
  return (
    <div
      style={{ position: "relative", width: "100%", height, ...style }}
      className="d-flex align-items-center justify-content-center"
    >
      {children}
      {(loading || !children) && (
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
}

export default ChartContainer;
