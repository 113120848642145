import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/esm/Spinner";
import { Navigate, useSearchParams } from "react-router-dom";

import { ListTeamsResponseItem } from "./backend";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import TeamDropdown from "./components/TeamDropdown";
import { useGlobal } from "./contexts/GlobalContext";

function SlackRedirect() {
  const [searchParams] = useSearchParams();
  const { setActiveTeam, teams, backendClient } = useGlobal();
  const [success, setSuccess] = useState(false);

  const configureSlackIntegration = async (team: ListTeamsResponseItem, code: string) => {
    if (backendClient) {
      await backendClient.teams.configureSlackIntegration({ teamId: team.id, requestBody: code });
      setSuccess(true);
    }
  };

  useEffect(() => {
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (backendClient && teams && code && state) {
      const stateParsed = new URLSearchParams(state);
      const teamId = parseInt(stateParsed.get("team_id") || "0");
      const team = teams.find((team) => team.id === teamId);

      if (team) {
        setActiveTeam(team);
        configureSlackIntegration(team, code);
      }
    }
  }, [searchParams, teams, backendClient]);

  return (
    <MainLayout>
      <div className="SlackRedirect">
        <PageHeader>
          <>Settings</>
          <TeamDropdown />
        </PageHeader>
        {!success && (
          <Alert variant="primary">
            <div className="d-flex flex-row gap-3 align-items-center">
              <Spinner animation="border" role="status" variant="primary" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <div>Configuring Slack integration...</div>
            </div>
          </Alert>
        )}
        {success && <Navigate to="/settings?configureIntegrations" />}
      </div>
    </MainLayout>
  );
}

export default SlackRedirect;
