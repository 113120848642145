import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useState } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import Stack from "react-bootstrap/Stack";

import { ListAlertRulesResponseItem } from "../backend";
import Pagination from "../components/Pagination";
import TableCard from "../components/TableCard";
import { useGlobal } from "../contexts/GlobalContext";
import { formatDateTimeDiff } from "../utils/datetime";

type AlertRuleHistoryTableProps = {
  alertRule?: ListAlertRulesResponseItem;
};

function AlertRuleHistoryTable({ alertRule }: AlertRuleHistoryTableProps) {
  const { backendClient, timezone } = useGlobal();
  const [page, setPage] = useState(1);

  const type = alertRule?.type === "custom" ? `custom:${alertRule.id}` : alertRule?.type;
  const queryParams = {
    appId: alertRule?.app.id || 0,
    appEnvId: alertRule?.app_env.id || 0,
    type: type || "",
    period: "24h",
  };
  const query = useQuery({
    queryKey: ["alerts", queryParams],
    queryFn: () => backendClient!.alerts.listAlerts(queryParams),
    enabled: !!backendClient && !!alertRule,
    placeholderData: undefined,
    refetchInterval: 60000,
  });

  const itemsPerPage = 10;
  const showingItemsFrom = itemsPerPage * (page - 1) + 1;
  const showingItemsTo = Math.min(itemsPerPage * page, query.data?.length || 0);
  const numberOfPages = Math.ceil((query.data?.length || 0) / itemsPerPage);
  const tableFooter =
    numberOfPages > 1 ? (
      <Stack direction="horizontal">
        <div className="small text-muted">
          Showing items {showingItemsFrom.toLocaleString()}-{showingItemsTo.toLocaleString()} of{" "}
          {query.data?.length.toLocaleString() || "0"}
        </div>
        <div className="ms-auto">
          <Pagination page={page} numberOfPages={numberOfPages} setPage={setPage} />
        </div>
      </Stack>
    ) : undefined;

  const rows = query.data?.slice(itemsPerPage * (page - 1), itemsPerPage * page).map((item) => {
    const triggeredAt = DateTime.fromISO(item.triggered_at, { zone: "utc" }).setZone(timezone);
    const resolvedAt = item.resolved_at
      ? DateTime.fromISO(item.resolved_at, { zone: "utc" }).setZone(timezone)
      : undefined;
    return (
      <tr key={item.id}>
        <td style={{ width: 40 }}>
          <FontAwesomeIcon icon={faClock} className="ms-2 text-very-muted" />
        </td>
        <td className="text-nowrap">{triggeredAt.toLocaleString(DateTime.DATETIME_MED)}</td>
        <td className="text-nowrap">{resolvedAt?.toLocaleString(DateTime.DATETIME_MED) || "-"}</td>
        <td className="text-nowrap">{formatDateTimeDiff(triggeredAt, resolvedAt) || "-"}</td>
      </tr>
    );
  });

  return (
    <TableCard
      responsive
      hover={query.isSuccess && query.data.length > 0}
      borderTop={false}
      className="AlertRuleHistoryTable align-middle"
      footer={tableFooter}
    >
      <thead>
        <tr>
          <th style={{ width: 40 }}></th>
          <th>Triggered at</th>
          <th>Resolved at</th>
          <th>Triggered for</th>
        </tr>
      </thead>
      <tbody>
        {rows}
        {query.isPending && (
          <Placeholder as="tr" animation="glow">
            <td style={{ width: 40 }}></td>
            <td>
              <Placeholder xs={6} />
            </td>
            <td>
              <Placeholder xs={6} />
            </td>
            <td>
              <Placeholder xs={6} />
            </td>
          </Placeholder>
        )}
        {query.isSuccess && query.data.length === 0 && (
          <tr>
            <td colSpan={4} className="py-6 text-center">
              This alert has not been triggered in the last 30 days.
            </td>
          </tr>
        )}
      </tbody>
    </TableCard>
  );
}

export default AlertRuleHistoryTable;
