import {
  faCheckCircle,
  faExclamationCircle,
  faEye,
  faGear,
  faHeartPulse,
  faRotateRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Placeholder from "react-bootstrap/Placeholder";
import { useSearchParams } from "react-router-dom";

import "./Uptime.css";
import { ListAppsResponseItem, UptimeChartsResponse, UptimePercentages } from "./backend";
import UptimeBarChart from "./charts/UptimeBarChart";
import AppEnvBadge from "./components/AppEnvBadge";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import PeriodDropdown, { PeriodDropdownItems } from "./components/PeriodDropdown";
import PlanLimitsExceededAlert from "./components/PlanLimitsExceededAlert";
import RefreshButton from "./components/RefreshButton";
import TableCard from "./components/TableCard";
import TeaserCard from "./components/TeaserCard";
import Tooltip from "./components/Tooltip";
import { useGlobal } from "./contexts/GlobalContext";
import HealthChecksFormModal from "./modals/HealthChecksFormModal";
import { getInitialPeriod } from "./utils/period";

function getDefaultTimeWindows(period: string): string[] {
  const nMap = new Map<string, number>([
    ["12h", 145],
    ["24h", 145],
    ["2d", 145],
    ["7d", 169],
    ["30d", 121],
  ]);
  const n = nMap.get(period) || 0;
  const windows: string[] = [];
  let time = DateTime.local();
  for (let i = 0; i < n; i++) {
    windows.unshift(time.toISO()!);
    time = time.minus({ minutes: 1 });
  }
  return windows;
}

function formatUptimePercentage(x: number): string {
  if (x >= 1.0) {
    return "100%";
  } else if (x >= 0.9999) {
    return "99.99%";
  } else {
    return x.toLocaleString(undefined, { style: "percent", minimumFractionDigits: x > 0.99 ? 2 : 1 });
  }
}

type UptimeCardProps = {
  app: ListAppsResponseItem;
  period: string;
  uptimeChartData?: UptimeChartsResponse;
  uptimePercentages?: Record<number, Record<string, UptimePercentages>>;
  setHealthChecksModalApp: React.Dispatch<React.SetStateAction<ListAppsResponseItem | undefined>>;
  className?: string;
};

function UptimeCard({
  app,
  period,
  uptimeChartData,
  uptimePercentages,
  setHealthChecksModalApp,
  className,
}: UptimeCardProps) {
  const { isTeamAdmin, isDemo } = useGlobal();
  const showAllInitial = !app.envs.some((env) => env.active);
  const [showAll, setShowAll] = useState(showAllInitial);
  const inactiveEnvs = app.envs.filter((env) => !env.active);
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  const appEnvRows = app.envs
    .filter((env) => env.active || showAll)
    .map((env) => {
      const statusTimeSeries = uptimeChartData?.apps[app.id]?.[env.slug];
      const uptimePercentage = uptimePercentages?.[app.id]?.[env.slug]?.uptime || 0;
      const healthyPercentage = uptimePercentages?.[app.id]?.[env.slug]?.healthy || 0;
      const goodUptimePercentage = uptimePercentage == 1.0;
      const goodHealthyPercentage = healthyPercentage == 1.0;
      const healthChecksDataAvailable = statusTimeSeries?.some((status) => status.health_checks !== null);
      return (
        <tr key={env.slug}>
          <td colSpan={2} className="px-4">
            <div>
              <AppEnvBadge env={env} />
              <div className="d-inline small text-end mt-2 float-end">
                {!uptimeChartData && (
                  <Placeholder animation="glow">
                    <Placeholder as="span" xs={12} style={{ width: 240 }} />
                  </Placeholder>
                )}
                {uptimeChartData && (
                  <>
                    {env.health_check_enabled && healthChecksDataAvailable && (
                      <Tooltip tooltip="Healthy means that your application is reachable and responding to health check requests made by Apitally">
                        <span
                          className={classNames("ms-4", {
                            "text-primary": goodHealthyPercentage,
                            "text-danger": !goodHealthyPercentage,
                          })}
                          style={{ cursor: "help" }}
                        >
                          <FontAwesomeIcon
                            icon={goodHealthyPercentage ? faCheckCircle : faExclamationCircle}
                            className="me-icon"
                          />
                          {formatUptimePercentage(healthyPercentage)} healthy
                        </span>
                      </Tooltip>
                    )}
                    {env.health_check_enabled && !healthChecksDataAvailable && (
                      <Tooltip tooltip="Health checks are enabled but there are no results available yet">
                        <span className="text-secondary ms-4 d-none d-sm-inline" style={{ cursor: "help" }}>
                          <FontAwesomeIcon icon={faSpinner} className="me-icon" />
                          Health checks pending
                        </span>
                      </Tooltip>
                    )}
                    {!env.health_check_enabled && (
                      <Tooltip tooltip="Health checks verify that your application is reachable and responding to requests from the internet">
                        <span className="text-secondary ms-4 d-none d-sm-inline" style={{ cursor: "help" }}>
                          <FontAwesomeIcon icon={faExclamationCircle} className="me-icon" />
                          Health checks disabled
                        </span>
                      </Tooltip>
                    )}
                    <Tooltip tooltip="Uptime means that your application is running and reporting data to Apitally">
                      <span
                        className={classNames("ms-4", {
                          "text-primary": goodUptimePercentage,
                          "text-danger": !goodUptimePercentage,
                        })}
                        style={{ cursor: "help" }}
                      >
                        <FontAwesomeIcon
                          icon={goodUptimePercentage ? faCheckCircle : faExclamationCircle}
                          className="me-icon"
                        />
                        {formatUptimePercentage(uptimePercentage)} uptime
                      </span>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            <div className="mt-4 mb-1">
              <UptimeBarChart
                timeWindows={uptimeChartData?.time_windows || getDefaultTimeWindows(period)}
                data={statusTimeSeries}
              />
            </div>
          </td>
        </tr>
      );
    });

  const tableFooterElements = [];
  if (isTeamAdminOrDemo) {
    tableFooterElements.push(
      <Button
        key="configure-health-checks"
        variant="link"
        className="small me-4"
        onClick={() => setHealthChecksModalApp(app)}
      >
        <FontAwesomeIcon icon={faGear} className="me-icon" />
        Configure health checks
      </Button>,
    );
  }
  if (inactiveEnvs && inactiveEnvs.length > 0) {
    if (!showAll) {
      tableFooterElements.push(
        <Button key="show-non-prod" variant="link" onClick={() => setShowAll(true)}>
          <FontAwesomeIcon icon={faEye} className="me-icon" />
          Show all environments
        </Button>,
      );
    }
  }
  const tableFooter =
    tableFooterElements.length > 0 ? <div className="small footer-actions">{tableFooterElements}</div> : undefined;

  return (
    <TableCard responsive hover={false} key={app.id} footer={tableFooter} className={className}>
      <thead>
        <tr>
          <th className="ps-4">{app.name}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{appEnvRows}</tbody>
    </TableCard>
  );
}

function UptimeTeaser() {
  const { launchDemo } = useGlobal();

  const pageHeaderButtons = (
    <Button variant="outline-light" className="float-end" onClick={() => launchDemo()}>
      Explore with demo data
    </Button>
  );
  return (
    <MainLayout>
      <PageHeader buttons={pageHeaderButtons}>
        <>Uptime</>
        <span className="text-muted">No apps set up yet</span>
      </PageHeader>
      <TeaserCard icon={faHeartPulse}>
        <h2>Be the first to know if your API is down</h2>
        <p className="mt-4">
          Let Apitally monitor the uptime and availability of your API and receive an instant alert via email if there
          are any problems, so you can fix them before they impact consumers.
        </p>
        <ul className="my-4">
          <li>
            <b>Uptime checks:</b> Apitally automatically checks whether your application is running and sending
            heartbeats every minute.
          </li>
          <li>
            <b>Health checks:</b> Send HTTP GET requests to a specified endpoint in 1 minute intervals to monitor the
            availability of your API.
          </li>
        </ul>
      </TeaserCard>
    </MainLayout>
  );
}

function Uptime() {
  const { activeTeam, apps, backendClient, isTeamAdmin, isDemo } = useGlobal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [healthChecksModalApp, setHealthChecksModalApp] = useState<ListAppsResponseItem>();
  const queryClient = useQueryClient();
  const isLoading = useIsFetching() > 0;
  const isTeamAdminOrDemo = isTeamAdmin || isDemo;

  useEffect(() => {
    document.title = "Uptime - Apitally";
  }, []);

  const period = searchParams.get("period") || getInitialPeriod({ key: "uptimePeriod", allowCustom: false });
  const setPeriod = (period: string) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set("period", period);
        return searchParams;
      },
      { replace: true },
    );
    localStorage.setItem("uptimePeriod", period);
  };

  useEffect(() => {
    if (!searchParams.has("period")) {
      setPeriod(period);
    }
  }, []);

  useEffect(() => {
    if (searchParams.has("configureHealthChecks")) {
      const configureHealthChecksAppId = parseInt(searchParams.get("configureHealthChecks") || "");
      const app = apps?.find((app) => app.id === configureHealthChecksAppId);
      if (app) {
        setHealthChecksModalApp(app);
      }
      setSearchParams((searchParams) => {
        searchParams.delete("configureHealthChecks");
        return searchParams;
      });
    }
  }, [searchParams]);

  const uptimeChartsQueryParams = { teamId: activeTeam?.id || 0, period };
  const uptimeChartsQuery = useQuery({
    queryKey: ["uptimeCharts", uptimeChartsQueryParams],
    queryFn: () => backendClient!.uptime.getUptimeCharts(uptimeChartsQueryParams),
    enabled: !!backendClient && !!activeTeam,
  });
  const uptimePercentagesQueryParams = { teamId: activeTeam?.id || 0, period };
  const uptimePercentagesQuery = useQuery({
    queryKey: ["uptimePercentages", uptimePercentagesQueryParams],
    queryFn: () => backendClient!.uptime.getUptimePercentages(uptimePercentagesQueryParams),
    enabled: !!backendClient && !!activeTeam,
  });
  const refresh = async () => {
    await queryClient.refetchQueries({ type: "active" });
  };

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  } else if (apps.length === 0) {
    return <UptimeTeaser />;
  }

  const periods = ["12h", "24h", "2d", "7d", "30d"];
  const pageHeaderButtons = [
    <PeriodDropdown key="period-dropdown" periods={periods} period={period} setPeriod={setPeriod} />,
    <RefreshButton key="refresh-button" onClick={refresh} loading={isLoading} />,
  ];
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" disabled={isLoading} onClick={refresh}>
        <FontAwesomeIcon icon={faRotateRight} fixedWidth />
        Refresh
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Period</Dropdown.Header>
      <PeriodDropdownItems periods={periods} period={period} setPeriod={setPeriod} />
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="Uptime">
        {isTeamAdminOrDemo && <HealthChecksFormModal app={healthChecksModalApp} setApp={setHealthChecksModalApp} />}
        <PageHeader buttons={pageHeaderButtons} dropdownMenu={pageHeaderDropdown}>
          Uptime
        </PageHeader>
        <PlanLimitsExceededAlert />
        {apps.map((app, i) => (
          <UptimeCard
            key={app.id}
            app={app}
            period={period}
            uptimeChartData={uptimeChartsQuery.data}
            uptimePercentages={uptimePercentagesQuery.data}
            setHealthChecksModalApp={setHealthChecksModalApp}
            className={classNames({ "mt-4 mt-lg-6": i > 0 })}
          />
        ))}
      </div>
    </MainLayout>
  );
}

export default Uptime;
