import { faCircleCheck, faCircleXmark, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import Placeholder from "react-bootstrap/Placeholder";

import TableCard from "../components/TableCard";
import { useGlobal } from "../contexts/GlobalContext";
import { formatPeriodStep } from "../utils/period";

type HealthChecksTableProps = {
  appId: number;
  appEnv: string;
};

function HealthChecksTable({ appId, appEnv }: HealthChecksTableProps) {
  const { backendClient, timezone } = useGlobal();

  const queryParams = {
    appId,
    appEnv,
    period: "24h",
  };
  const query = useQuery({
    queryKey: ["healthChecksTable", queryParams],
    queryFn: () => backendClient!.uptime.getHealthChecksTable(queryParams),
    enabled: !!backendClient && !!appId && !!appEnv,
    placeholderData: undefined,
    refetchInterval: 60000,
  });

  const rows = query.data?.map((item) => {
    const firstTimestamp = DateTime.fromISO(item.first_timestamp, { zone: "utc" }).setZone(timezone);
    const lastTimestamp = DateTime.fromISO(item.last_timestamp, { zone: "utc" }).setZone(timezone);
    const period = formatPeriodStep(firstTimestamp, lastTimestamp);
    const statusIcon = item.success ? faCircleCheck : faCircleXmark;
    const statusClass = item.success ? "text-primary" : "text-danger";
    return (
      <tr key={item.first_timestamp}>
        <td style={{ width: 40 }}>
          <FontAwesomeIcon icon={faClock} className="ms-2 text-very-muted" />
        </td>
        <td style={{ width: 290 }}>{period}</td>
        <td style={{ width: 160 }} className={`${statusClass} text-nowrap`}>
          <FontAwesomeIcon icon={statusIcon} className="me-icon" />
          {item.status_code > 0 ? <>{item.status_code.toString()}</> : "Timeout"}
        </td>
        <td>{item.error || "-"}</td>
        <td className="pe-4 text-end text-very-muted">
          {item.count && item.count > 1 && `${item.count.toLocaleString()}x`}
        </td>
      </tr>
    );
  });

  return (
    <TableCard
      responsive
      hover={query.isSuccess && query.data.length > 0}
      borderTop={false}
      className="HealthChecksTable align-middle"
    >
      <thead>
        <tr>
          <th style={{ width: 40 }}></th>
          <th style={{ minWidth: 180 }}>Period</th>
          <th style={{ minWidth: 110 }}>Status</th>
          <th style={{ minWidth: 220 }}>Error</th>
          <th style={{ width: 40 }}></th>
        </tr>
      </thead>
      <tbody>
        {rows}
        {query.isPending && (
          <Placeholder as="tr" animation="glow">
            <td style={{ width: 40 }}></td>
            <td style={{ width: 290 }}>
              <Placeholder xs={9} />
            </td>
            <td style={{ width: 160 }}>
              <Placeholder xs={6} bg="primary" />
            </td>
            <td>
              <Placeholder xs={9} />
            </td>
            <td style={{ width: 40 }}></td>
          </Placeholder>
        )}
        {query.isSuccess && query.data.length === 0 && (
          <tr>
            <td colSpan={4} className="py-6 text-center">
              There are no health check results yet.
            </td>
          </tr>
        )}
      </tbody>
    </TableCard>
  );
}

export default HealthChecksTable;
